<div class="container full-width">
  <div class="row m-auto">
    <div class="col-2 navigation">
      <div class="main">
        <div class="navigation-steps">
          <!-- main logo -->
          <div class="logo-box">
            <img
              class="logo"
              src="../../../assets/img/welcome-Logo.png"
              alt="column-logo"
            />
          </div>

          <!-- navigation -->
          <ul class="steps" *ngrxLet="stepList$ as list">
            <ng-container
              *ngFor="
                let item of list;
                index as i;
                last as isLast;
                trackBy: trackByFn
              "
            >
              <li
                *ngIf="!stepsNav[i] == ''"
                class="steps__item"
                #itemRef
                [ngStyle]="collapse(item, itemRef)"
              >
                <div
                  class="item"
                  [ngClass]="{
                    progres: item.status === IN_PROGRESS,
                    finished: item.status === FINISHED
                  }"
                >
                  <div
                    class="item--status"
                    *ngIf="item.status === FINISHED; else unfinished"
                  >
                    <svg class="icon icon-check">
                      <use href="./assets/icons.svg#icon-check-alt"></use>
                    </svg>
                  </div>
                  <ng-template #unfinished>
                    <div class="item--status">
                      <span class="icon-empty">{{ i + 1 }}</span>
                    </div>
                  </ng-template>
                  <div class="item--name">{{ stepsNav[i] }}</div>
                </div>
              </li>
            </ng-container>
            <li class="translate">
              <a class="language-translate" (click)="OnlanguageChange()"
                ><mat-icon>translate</mat-icon
                ><span class="language-text">{{ languageValue }}</span></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Onboarding uncapable customer message -->
    <div class="col-10 col-xs-12">
      <div class="row">
        <div class="col-12">
          <div *ngIf="!onBoardCapable" class="container height-80 p-2">
            <div>
              <div class="row">
                <div class="col-12">
                  <div class="content-info">
                    <div class="height-10"></div>
                    <div class="text-center p-4">
                      <img src="../assets/img/page-img/confused.png" alt="" style="max-width: 200px;">
                      <h6>
                        {{ "subscribe.text-1" | translate }}
                      </h6>
                      <h6>{{ "subscribe.text-2" | translate }}</h6>
                      <h6>{{ "subscribe.text-3" | translate }}</h6>
                      <div class="mt-4">
                        <a
                          href="https://appsource.microsoft.com/en-us/product/web-apps/evolvenowgmbh1593800869666.evolve365_security"
                          class="btn btn-success me-3"
                          >{{ "subscribe.btn-subscribe" | translate }}
                        </a>
                        <a
                          href="https://securescore.evolve365.cloud/"
                          class="btn btn-success"
                          >{{ "subscribe.btn-try" | translate }}</a
                        >
                      </div>
                      <div class="mt-4">
                        <h6 class="mt-4">
                          {{ "finish.problem" | translate}} {{ "finish.contact" | translate }}
                        </h6>
                        <a
                          href="mailto:&#105;&#110;&#102;&#111;&#64;&#101;&#118;&#111;&#108;&#118;&#101;&#51;&#54;&#53;&#46;&#99;&#108;&#111;&#117;&#100;&#10;"
                          >&#105;&#110;&#102;&#111;&#64;&#101;&#118;&#111;&#108;&#118;&#101;&#51;&#54;&#53;&#46;&#99;&#108;&#111;&#117;&#100;&#10;</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Onboarding capable switch component -->
        <div class="col-12 top-space-main"></div>
        <div *ngIf="onBoardCapable">
          <ng-container *ngrxLet="currentStepIndex$ as step">
            <div class="col-12 d-flex flex-column">
              <div
                class="content-info"
                #el
                [ngSwitch]="(selectedStep$ | async)?.type"
              >
                <nb-app-permission
                  *ngSwitchCase="type.APP_PERMISSION"
                ></nb-app-permission>
                <nb-tenant-overview
                  [consentValue]="consentValue"
                  *ngSwitchCase="type.TENANT_DETAIL"
                ></nb-tenant-overview>
                <nb-admin *ngSwitchCase="type.ADMIN"></nb-admin>
                <nb-settings *ngSwitchCase="type.SETTINGS"></nb-settings>
                <nb-submit *ngSwitchCase="type.SUBMIT"></nb-submit>
                <nb-finish *ngSwitchCase="type.SUCCESS"></nb-finish>
                <div *ngSwitchDefault>
                  <p class="not-found">
                    No step found for type
                    {{ (selectedStep$ | async)?.title }} :(
                  </p>
                </div>
              </div>
            </div>

            <!-- next and previous button -->
            <div class="col-12">
              <div class="row prev-nex-button" *ngIf="!finish">
                <div class="col-6 float-left">
                  <div class="actions">
                    <button
                      class="btn btn--prev"
                      *ngIf="!ispermissionPage"
                      (click)="preved(step, el)"
                    >
                      {{ "menu.btn.previous" | translate }}
                    </button>
                  </div>
                </div>
                <div class="col-6 actions">
                  <button
                    *ngIf="!finishStep"
                    type="submit"
                    class="btn btn--next float-right"
                    (click)="updated(step, el)"
                    [disabled]="!disableRoute"
                  >
                    {{ "menu.btn.next" | translate }}
                  </button>
                  <button
                    *ngIf="finishStep"
                    type="submit"
                    class="btn btn--next float-right"
                    (click)="updated(step, el)"
                    [disabled]="!disableRoute"
                  >
                    {{ "menu.steps.submit" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
