import { Injectable } from '@angular/core';
import { Status } from '../model-form/status.model';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  private status: Status = {
    registeredDate: "",
    activationDate: "",
    cancelationDate: "",
    token:""
  };
  getStatus() {
    return this.status;
  }
  constructor() {}
  addStatus(status: Status) {
    this.status = status;
  }

}

