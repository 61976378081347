import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { CustomerData } from "../models/comstomerData.model";
import { Admin } from "../model-form/admin.model"; 
import { Contacts } from "../model-form/contacts.model"; 
import { Profile } from "../model-form/profile.model"; 
import { Setting } from "../model-form/setting.model"; 
import { Status } from "../model-form/status.model";
import { ContactsService } from "./contacts.service";
import { SettingService } from "./setting.service"; 
import { StatusService } from "./status.service"; 
import { TenantIdService } from "./tenant-id.service"; 

@Injectable({
  providedIn: "root",
})
export class CommonService {
  conflict: boolean = false;
  settings: Setting;
  admin: Admin;
  data: CustomerData;
  profile: Profile;
  contacts: Contacts;
  customerDatas: CustomerData;
  id: string;
  status: Status;
  todayISOString: string;

  errorPostMessage: string;

  private routerInfo: BehaviorSubject<boolean>;
  private finishStep: BehaviorSubject<boolean>;
  private finish: BehaviorSubject<boolean>;
  constructor(
    private http: HttpClient,
    private settingService: SettingService,
    private contactsService: ContactsService,
    private statusService: StatusService,
    private tenantId: TenantIdService
  ) {
    this.todayISOString = new Date().toISOString();
    this.routerInfo = new BehaviorSubject<boolean>(false);
    this.finishStep = new BehaviorSubject<boolean>(false);
    this.finish = new BehaviorSubject<boolean>(false);
  }

  getValue(): Observable<boolean> {
    return this.routerInfo.asObservable();
  }
  setValue(newValue): void {
    this.routerInfo.next(newValue);
  }

  getFinishStep(): Observable<boolean> {
    return this.finishStep.asObservable();
  }

  setFinishStep(finishValue): void {
    this.finishStep.next(finishValue);
  }

  getFinish(): Observable<boolean> {
    return this.finish.asObservable();
  }

  setFinish(finish): void {
    this.finish.next(finish);
  }

  OnPush() {
    this.id = this.tenantId.getTenantID();
    this.contacts = this.contactsService.getContacts();
    this.settings = this.settingService.getSetting();
    this.status = this.statusService.getStatus();
    this.addData(
      this.id,
      this.contacts,
      this.settings,
      this.status,
    );
  }
  addData(
    id: string,
    contacts: Contacts,
    settings: Setting,
    status: Status,
  ) {
    this.customerDatas = {
      id,
      contacts,
      settings,
      status,
    };
  }
  OnAddData():Observable<any> {
    console.log(this.customerDatas)
    return this.http.post<CustomerData>('/api/New-CosmosDBCustomerEntry', this.customerDatas)
    // .subscribe((res)=>{
    //     return null
    // },(err) => {
    //   this.conflict = err.error.conflict;
    // });

  }
  OngetData(){
    return this.customerDatas;
    
  }
}