
//const appId = "84280e82-6178-47a7-886d-98d3002f8218";
//const redirectUrl = "https://evolve365onboardingdev.azurewebsites.net/";
/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */
//  https://evolve365onboarding-dev.azurewebsites.net/

import { LogLevel, Configuration, BrowserCacheLocation, AuthenticationScheme } from '@azure/msal-browser';
import { environment } from 'environments/environment';
const AppID =  environment.AppID;
const RedirectAppUri = environment.RedirectAppUri;
// const appId = process.env.AzureADAppIdEvolve365Security;
// const redirectUrl = process.env.WebAppHostName;
// const redirectUrl = "https://ashy-bay-068d0cc03-dev.westeurope.2.azurestaticapps.net/";
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

// clientId: '84280e82-6178-47a7-886d-98d3002f8218',  This is the ONLY mandatory field that you need to supply.
//     authority: 'https://login.microsoftonline.com/common', Defaults to "https://login.microsoftonline.com/common"organizations
export const msalConfig: Configuration = {
  auth: {
    clientId: AppID, // This is the ONLY mandatory field that you need to supply.
    authority: 'https://login.microsoftonline.com/organizations/', // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: RedirectAppUri // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. "SessionStorage" is more secure, but "LocalStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {

      },
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false
    }
  }
}

/**
 * Add here the endpoints and scopes when obtaining an access  for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  protectedApi: {
    endpoint: RedirectAppUri,
    scopes: ['user.read', 'Directory.ReadWrite.All', 'AuditLog.Read.All', 'SecurityEvents.ReadWrite.All'],
  },
}


/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [...protectedResources.protectedApi.scopes],
  authenticationScheme: AuthenticationScheme.POP,
  resourceRequestMethod: "GET",
  resourceRequestUri: protectedResources.protectedApi.endpoint,
  protectedResourceMap: new Map([
    ['https://graph.microsoft.com/v1.0/organizations', ['user.read']],
    ['https://graph.microsoft.com/v1.0/organization', ['Directory.ReadWrite.All']],
    ['https://graph.microsoft.com/v1.0/users', ['Directory.ReadWrite.All']],
    ['https://graph.microsoft.com/beta/users', ['Directory.ReadWrite.All']],
    ['https://graph.microsoft.com/beta/reports/authenticationMethods/usersRegisteredByFeature', ['AuditLog.Read.All']],
    ['https://graph.microsoft.com/beta/security/secureScores', ['SecurityEvents.Read.All']],
    ['https://graph.microsoft.com/beta/security/secureScores', ['SecurityEvents.ReadWrite.All']],
  ])
};
