import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'app/onboarding/services/common.service'; 

@Component({
  selector: 'nb-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss']
})
export class FinishComponent implements OnInit, OnDestroy {

  // Contact mail in unicode decimal code for spam protection
  emailto = '&#109;&#097;&#105;&#108;&#116;&#111;:';
  cont = '&#105;&#110;&#102;&#111;'
  at = '&#064;';
  name = '&#101;&#118;&#111;&#108;&#118;&#101;&#045;&#110;&#111;&#119;';
  httpError:number = 0;
  errorPage:boolean = false
  isIdExist: boolean = false; // user id is on Database
  loading: boolean = true // fetching data loader

  constructor(private commonService: CommonService) {
    this.commonService.setFinish(true);
   }

  ngOnInit(): void {

    // call function on page loads 
    this.OnaddData();

    // change the next button text to finish
    this.commonService.setFinish(true);
  }

  // Push data to CosmosDB only if tenant id is not available on CosmosDB
  OnaddData(){
    this.commonService.OnAddData().subscribe({
    complete: () => {  },
    error: (err) => { 
      this.httpError = err.status
      if(this.httpError === 409){
        this.isIdExist = true;
        this.loading = false;
      }else{
        this.errorPage = true;
        this.loading = false;
      }
        // if(this.isIdExist ){
        //   this.isIdExist = true;
        //   this.loading= false;
        //   console.clear();
        // }
      },    
    next: (res) => { 
      this.isIdExist = false;
      this.loading = false }     
});}


  ngOnDestroy(): void {
  }

}
