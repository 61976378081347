import { Injectable } from '@angular/core';
import { Admin } from '../model-form/admin.model';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private admin: Admin = {
    firstName: '',
    lastName: '',
    email: '',
    companyName:'',
    licensedUsers: 0,
  };
  getAdmin() {
    return this.admin;
  }
  constructor() {}
  addAdmin(admin: Admin,  licensedUsers: number) {
    this.admin = admin;
    this.admin.licensedUsers = licensedUsers;
  }

}
