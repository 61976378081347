<nb-header headingMain="{{ 'submit.head' | translate }}"
  headingSecondary="{{ 'submit.subtitle' | translate }}"></nb-header>

<div class="container">
  <div class="row">
    <div class="col-12 height-80"></div>
    <main class="page payment-page">
      <section class="payment-form dark">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <!-- <div class=" col-4 border d-flex align-items-center justify-content-center">
                <img src="../../../../assets/img/page-img/confirm.png" alt="" class="img-fluid" style="max-width: 200px;">
              </div> -->
              <div class="col-12 text-center">
                <img src="../../../../assets/img/page-img/confirm.png" alt="" class="img-fluid"
                  style="max-width: 200px" />
              </div>
            </div>
          </div>
          <div class="row products payment-form">
            <div class="col-12 col-md-6">
              <form>
                <h3 class="title text-center">
                  {{ "submit.companyProfile" | translate }}
                </h3>
                <div class="item">
                  <span class="price">{{
                    submitData.contacts.companyProfile.companyName
                    }}</span>
                  <p class="fw-bold item-description">
                    {{ "admin.body.companyName" | translate }}
                  </p>
                </div>
                <div class="item">
                  <span class="price">{{
                    submitData.contacts.companyProfile.firstName
                    }}</span>
                  <p class="fw-bold item-description">
                    {{ "admin.body.firstName" | translate }}
                  </p>
                </div>
                <div class="item">
                  <span class="price">{{
                    submitData.contacts.companyProfile.lastName
                    }}</span>
                  <p class="fw-bold item-description">
                    {{ "admin.body.lastName" | translate }}
                  </p>
                </div>
                <div class="item">
                  <span class="price">{{
                    submitData.contacts.companyProfile.email
                    }}</span>
                  <p class="fw-bold item-description">Email</p>
                </div>
              </form>
            </div>
            <div class="col-12 col-md-6">
              <form>
                <h3 class="title text-center">
                  {{ "submit.setting" | translate }}
                </h3>
                <div class="item">
                  <span *ngIf="isIp; else elseBlock" class="price">{{
                    "setting.body.yesOrNo.yes" | translate
                    }}</span>
                  <ng-template #elseBlock>
                    <span class="price">{{
                      "setting.body.yesOrNo.no" | translate
                      }}</span>
                  </ng-template>

                  <p class="fw-bold item-description">
                    {{ "submit.fixedIp" | translate }}
                  </p>
                </div>
                <div class="item" *ngIf="isIp">
                  <span class="price">{{
                    submitData.settings.staticIPAddress
                    }}</span>
                  <p class="fw-bold item-description">
                    {{ "submit.fixedIpaddress" | translate }}
                  </p>
                </div>
                <div class="item">
                  <span class="price">{{ newDate[0] }}/{{ newDate[1] }}/{{ newDate[2] }}</span>
                  <p class="fw-bold item-description">
                    {{ "submit.date" | translate }}
                  </p>
                </div>
                <div class="item">
                  <span class="price">{{
                    submitData.settings.trustedCountries
                    }}</span>
                  <p class="fw-bold item-description">
                    {{ "submit.country" | translate }}
                  </p>
                </div>
                <div class="item">
                  <span class="price">{{
                    submitData.settings.outsideTrustedCountries
                    }}</span>
                  <p class="fw-bold item-description">
                    MFA {{ "submit.setting" | translate }}
                  </p>
                </div>
                <div class="item">
                  <span *ngIf="isDevice; else elseDevice" class="price">{{
                    "setting.body.yesOrNo.yes" | translate
                    }}</span>
                  <ng-template #elseDevice>
                    <span class="price">{{
                      "setting.body.yesOrNo.no" | translate
                      }}</span>
                  </ng-template>

                  <p class="fw-bold item-description">
                    {{ "submit.automatDevice" | translate }}
                  </p>
                </div>
                <div class="item" *ngIf="isDevice">
                  <span class="price">{{
                    submitData.settings.serviceAccountUPN
                    }}</span>
                  <p class="fw-bold item-description">
                    {{ "submit.automatEmail" | translate }}
                  </p>
                </div>
              </form>
            </div>
            <!-- <div class="col-4">
              <img class="img-fluid" src="../../../../assets/img/page-img/confirm.png" alt="" style="max-width: 200px;">
              </div> -->

          </div>
        </div>
      </section>
    </main>
  </div>
</div>