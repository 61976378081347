import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nb-signin-cancel',
  templateUrl: './signin-cancel.component.html',
  styleUrls: ['./signin-cancel.component.scss']
})
export class SigninCancelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
