import { Injectable } from '@angular/core';
import { Profile } from '../model-form/profile.model';


@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private profile: Profile = {
    givenName:'', 
    surname:'',
    userPrincipalName:''
  };

  getProfile() {
    return this.profile;
  }
  constructor() {}
  addProfile(profile: Profile) {
    this.profile = profile;
  }
}
