<!-- Main heading and sub heading -->
<nb-header headingSecondary="{{ 'tenant.subtitle' | translate }}" headingMain="{{ 'tenant.head' | translate }}">
</nb-header>
<div color="container height-80">
  <div>
    <!-- If user is not logged in show consent button -->
    <div class="row" *ngIf="!loginDisplay">
      <div class="height-40"></div>
      <div class="col-12 ">
        <div class="text-center">
          <!-- Give consent text -->
          <div>
            <p> {{ 'tenant.loginMessage' | translate }}</p>
          </div>
          <button mat-raised-button *ngIf="consentValue && !loginDisplay" (click)="login()">{{ 'tenant.login' |
            translate }}</button>
          <a mat-raised-button *ngIf="!consentValue" [href]='consentUrl'>Consent</a>
        </div>

      </div>
    </div>
  </div>

  <div class="text-center justify-content-center">

    <!-- user login page -->
    <div *ngIf="loginDisplay">

      <!-- toggle switch for Mfa and secure score -->
      <div class="wrapper">
        <input type="radio" name="select" id="option-1" checked (click)="secureScore(false)">
        <input type="radio" name="select" id="option-2" (click)="secureScore(true)">
        <label for="option-1" class="option option-1">
          <div class="dot"></div>
          <span>MFA Security</span>
        </label>
        <label for="option-2" class="option option-2">
          <div class="dot"></div>
          <span>Security Check</span>
        </label>
      </div>
      <!-- show either secure score or mfa page if the switcch is toggled -->
      <nb-secure-score *ngIf="isSecureScore"></nb-secure-score>
      <nb-tenant-detail *ngIf="!isSecureScore"></nb-tenant-detail>
    </div>
  </div>

</div>