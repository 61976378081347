<nb-header
  headingSecondary="{{ 'setting.subtitle' | translate }}"
  headingMain="{{ 'setting.head' | translate }}"
>
</nb-header>

<!-------- element class is the tour class for the each  form field eg. class="second-element" ------->

<div class="container">
  <div class="row">
    <div class="col-12 height-80 margin-top scroll">
      <!-- Setting form starts -->
      <form [formGroup]="settingForm" class="example-form mt-4">
        <div class="row">

          <!-- Left side of the Page -->
          <div class="col-md-6 mt-4 col-sm-12 text-center">
            
            <!-- Setting form Static-Ip choice-->
            <div class="second-element">

              <div class="mb-2">
                <h6 class="d-inline">
                  {{ "setting.body.fixedIpAddress" | translate }}
                </h6>
                <mat-icon
                  color="accent"
                  matTooltip="{{
                    'setting.body.toolTip.fixedIpAddress' | translate
                  }}"
                  [matTooltipPosition]="position.value"
                  >info
                </mat-icon>
              </div>

              <mat-button-toggle-group
                #group="matButtonToggleGroup"
                name="staticIp"
                aria-label="Font Style"
                class="example-full-width mb-3"
                formControlName="staticIPAddressUsed"
              >
                <mat-button-toggle
                  (click)="selectionChangedIpT()"
                  value="true"
                  name="staticIpTrue"
                  >{{
                    "setting.body.yesOrNo.yes" | translate
                  }}</mat-button-toggle
                >
                <mat-button-toggle
                  (click)="selectionChangedIpF()"
                  value="false"
                  name="staticIpFalse"
                  >{{
                    "setting.body.yesOrNo.no" | translate
                  }}</mat-button-toggle
                >
              </mat-button-toggle-group>
            </div>

            <!-- Setting form static-IP address form -->
            <div class="second-element2" *ngIf="ipSelected">
              <h6 *ngIf="ipSelected">{{ "setting.body.cidr" | translate }}</h6>

              <mat-form-field
                *ngIf="ipSelected"
                class="mb-3"
                appearance="outline"
              >
                <mat-icon matPrefix>location_on</mat-icon>
                <input
                  type="text"
                  matInput
                  formControlName="staticIPAddress"
                  placeholder="192.1.1.168"
                />
                <mat-error
                  *ngIf="
                    settingForm.controls.staticIPAddress.hasError('email') &&
                    !settingForm.controls.staticIPAddress.hasError('required')
                  "
                >
                {{ "setting.body.hasError.ip" | translate }}
                </mat-error>
                <mat-error
                  *ngIf="settingForm.controls.staticIPAddress.hasError('required')"
                >
                {{ "setting.body.hasError.ipEmpty" | translate }} <strong>{{ "setting.body.hasError.required" | translate }}</strong>
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Setting form choose date for setup -->
            <div class="third-element">
              <div class="mb-2">
                <h6 class="d-inline">{{ "setting.body.date" | translate }}</h6>
                <mat-icon
                  color="accent"
                  matTooltip="{{ 'setting.body.toolTip.date' | translate }}"
                  [matTooltipPosition]="position.value"
                  >info</mat-icon
                >
              </div>

              <mat-form-field appearance="outline">
                <mat-icon matPrefix>calendar_today</mat-icon>
                <input
                  matInput
                  [matDatepicker]="picker"
                  [min]="minDate"
                  [max]="maxDate"
                  (dateInput)="addEvent('input', $event)"
                  (dateChange)="addEvent('change', $event)"
                  formControlName="assignmentDate"
                />
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon
                    >keyboard_arrow_down</mat-icon
                  >
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <!-- protect global admin -->
            <!-- <div class="fourth-element">
              <div class="mb-2">
                <h6 class="d-inline">
                  {{ "setting.body.globalAdmin" | translate }}
                </h6>
                <mat-icon
                  color="accent"
                  matTooltip="{{
                    'setting.body.toolTip.globalAdmin' | translate
                  }}"
                  [matTooltipPosition]="position.value"
                  >info</mat-icon
                >
              </div>

              <mat-button-toggle-group
                class="d-block"
                #group="matButtonToggleGroup"
                name="fontStyle"
                aria-label="Font Style"
                [value]="false"
                class="example-full-width mb-3"
                formControlName="globalAdmin"
                value="false"
              >
                <mat-button-toggle (click)="globalAdmin()" value="true"
                  >{{ "setting.body.yesOrNo.yes" | translate }}
                </mat-button-toggle>
                <mat-button-toggle (click)="globalAdminF()" value="false"
                  >{{ "setting.body.yesOrNo.no" | translate }}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div> -->
            <!-- <mat-error *ngIf="settingForm.controls.globalAdmin.hasError('required')">
          This field is <strong>required</strong>
        </mat-error> -->

            <!-- Setting form choose country -->

            <div class="fifth-element">
              <div class="mb-2">
                <h6 class="d-inline">
                  {{ "setting.body.country" | translate }}
                </h6>
                <mat-icon
                  class="mt-2"
                  color="accent"
                  matTooltip="{{ 'setting.body.toolTip.country' | translate }}"
                  [matTooltipPosition]="position.value"
                  >info</mat-icon
                >
              </div>

              <mat-select-country
                class="example-full-width country d-inline"
                appearance="outline"
                (onCountrySelected)="onCountrySelected($event)"
                formControlName="trustedCountries"
              >
                <!-- <mat-error
                  *ngIf="
                    settingForm.controls.selectedCountry.hasError('required')
                  "
                >
                  This field is <strong>required</strong>
                </mat-error> -->
              </mat-select-country>
            </div>
          </div>

          <!-- Right side of the page -->
          <div class="col-md-6 mt-4 col-sm-12 text-center">

            <!-- Setting form Mfa setting -->
            <div class="sixth-element">
              <div class="mb-2">
                <h6 class="d-inline">
                  {{ "setting.body.requestMfa" | translate }}
                </h6>
              </div>
              <div class="mfaToggle">
                <mat-button-toggle-group
                  name="fontStyle"
                  aria-label="Font Style"
                  [value]=""
                  #group="matButtonToggleGroup"
                  class="restriction example-full-width mb-3"
                  formControlName="outsideTrustedCountries"
                >
                  <mat-button-toggle class="text-start" value="MFA-Required"
                    >{{ "setting.body.mfa.require" | translate
                    }}<mat-icon
                      color="accent"
                      matTooltip="{{
                        'setting.body.toolTip.requestMfa.required' | translate
                      }}"
                      [matTooltipPosition]="position.value"
                      >info</mat-icon
                    >
                  </mat-button-toggle>

                  <mat-button-toggle class="block" value="Blocked"
                    >{{ "setting.body.mfa.blocked" | translate
                    }}<mat-icon
                      color="accent"
                      matTooltip="{{
                        'setting.body.toolTip.requestMfa.blocked' | translate
                      }}"
                      [matTooltipPosition]="position.value"
                      >info</mat-icon
                    >
                  </mat-button-toggle>

                  <mat-button-toggle class="no" value="No-Restriction"
                    >{{ "setting.body.mfa.restriction" | translate }}
                    <mat-icon
                      color="accent"
                      matTooltip="{{
                        'setting.body.toolTip.requestMfa.noRestrict' | translate
                      }}"
                      [matTooltipPosition]="position.value"
                      >info</mat-icon
                    >
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>

            <!-- setting form choose Service account -->
            <div class="seventh-element">
              <div class="mb-2">
                <h6 class="d-inline">
                  {{ "setting.body.automateDevice" | translate }}
                </h6>
                <mat-icon
                  color="accent"
                  matTooltip="{{
                    'setting.body.toolTip.automateDevice' | translate
                  }}"
                  [matTooltipPosition]="position.value"
                  >info</mat-icon
                >
              </div>

              <div class="yesNo">
                <mat-button-toggle-group
                  class="d-block"
                  #group="matButtonToggleGroup"
                  name="fontStyle"
                  aria-label="Font Style"
                  class="example-full-width"
                  formControlName="serviceAccountUsed"
                >
                  <mat-button-toggle
                    (click)="selectionChanged()"
                    value="true"
                    >{{
                      "setting.body.yesOrNo.yes" | translate
                    }}</mat-button-toggle
                  >
                  <mat-button-toggle
                    (click)="selectionChangedf()"
                    value="false"
                    >{{
                      "setting.body.yesOrNo.no" | translate
                    }}</mat-button-toggle
                  >
                </mat-button-toggle-group>
              </div>
            </div>

            <!-- Settting form Service account Email -->
            <div class="seventh-element2" *ngIf="useDevice">
              <h6 *ngIf="useDevice">Email</h6>
              <div class="useDevice">
                <mat-form-field *ngIf="useDevice" appearance="outline">
                  <mat-icon matPrefix>email</mat-icon>
                  <input
                    type="text"
                    matInput
                    formControlName="serviceAccountUPN"
                    placeholder="johnDoe@email.com"
                  />
                  <mat-error
                    *ngIf="
                      settingForm.controls.serviceAccountUPN.hasError('email') &&
                      !settingForm.controls.serviceAccountUPN.hasError('required')
                    "
                  >
                    {{ "setting.body.hasError.emailValid" | translate }}
                  </mat-error>
                  <mat-error
                    *ngIf="
                      settingForm.controls.serviceAccountUPN.hasError('required')
                    "
                  >
                  {{ "setting.body.hasError.emailEmpty" | translate }} <strong>{{ "setting.body.hasError.required" | translate }}</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!-- setting form ends -->
    </div>
  </div>
</div>
