import { TranslateService } from "@ngx-translate/core";
import { Steps } from "../models/Step";
import Step from 'shepherd.js/src/types/step';

export enum STEP_TYPES {
  APP_PERMISSION = 'APP_PERMISSION',
  TENANT_DETAIL = 'TENANT_DETAIL',
  ADMIN = 'ADMIN',
  SETTINGS = 'SETTINGS',
  SUBMIT = 'SUBMIT',
  SUCCESS = 'SUCCESS',
}

export class datas {
  constructor(private translator:TranslateService){

  }}
//
// STEPS DATA

export const steps: Steps = {

  APP_PERMISSION: {
    stepIndex: 0,
    title: 'App Permission',
    type: STEP_TYPES.APP_PERMISSION,
  },
  TENANT_DETAIL: {
    stepIndex: 1,
    title: 'Tenant Details',
    type: STEP_TYPES.TENANT_DETAIL,
  },
  ADMIN: {
    stepIndex: 2,
    title: 'Admin',
    type: STEP_TYPES.ADMIN,
  },
  SETTINGS: {
    stepIndex: 3,
    title: 'Setting',
    type: STEP_TYPES.SETTINGS,
    },
    SUBMIT: {
      stepIndex: 4,
      title: 'Submit',
      type: STEP_TYPES.SUBMIT,
      },
  SUCCESS: {
    stepIndex: 5,
    title: 'Success',
    type: STEP_TYPES.SUCCESS,
  }
};



export const builtInButtons = {
  cancel: {
    classes: 'cancel-button',
    secondary: true,
    text: 'Exit',
    type: 'cancel'
  },
  next: {
    classes: 'next-button',
    text: 'Next',
    type: 'next'
  },
  back: {
    classes: 'back-button',
    secondary: true,
    text: 'Back',
    type: 'back'
  }
};

export const defaultStepOptions: Step.StepOptions = {
  classes: 'shepherd-theme-arrows custom-default-class',
  scrollTo: true,
  cancelIcon: {
    enabled: true
  }
};


export const tourSteps: Step.StepOptions[] = [
  {
    attachTo: {
      element: '.first-element',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'intro',
    title: 'Hi need a guide?',
    text: `
          <p>
            This is a simple guide to explain each and every steps on this setting page.
            The setting page contains all basic configuration your company needed for us 
            to move on with seting up your tenant with our app.
           please make sure to read each steps before you move on to next steps.
           Ofcourse you have one more tiptool added to the each input in yellow color icon, you can always click on that to get short information.
           and you can also exit the guide anytime if you dont need it anymore         </p>
        
          <p>
           Please fill the input before you click next button.
          </p>`
  },
  {
    attachTo: {
      element: '.second-element',
      on: 'right'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: '.second-element',
    title: 'Ip address',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it '
  },
  {
    attachTo: {
      element: '.third-element',
      on: 'right'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'third-element',
    title: 'Date',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it '
  },
  {
    attachTo: {
      element: '.fourth-element',
      on: 'right'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'fourth-element',
    title: 'Date',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it '
  },
  {
    attachTo: {
      element: '.fifth-element',
      on: 'right'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'fifth-element',
    title: 'country',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it '
  },
  {
    attachTo: {
      element: '.sixth-element',
      on: 'right'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'sixth-element',
    title: 'MFA',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it '
  },
  {
    attachTo: {
      element: '.seventh-element',
      on: 'right'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'seventh-element',
    title: 'Device Email',
    text: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it '
  },


  
];
