import { Country } from "@angular-material-extensions/select-country";
import { Injectable } from "@angular/core";
import { Setting } from "../model-form/setting.model";

@Injectable({
  providedIn: "root",
})
export class SettingService {
  currentYear = new Date().getFullYear();
  todayDate = new Date().getDate();
  currentMonth = new Date().getMonth();

  private setting: Setting = {
    staticIPAddressUsed: "false",
    staticIPAddress: "",
    trustedCountries: {
      name: "Schweiz",
      alpha2Code: "CH",
      alpha3Code: "CHE",
      numericCode: "756",
      callingCode: "+41",
    },
    assignmentDate: new Date(
      this.currentYear,
      this.currentMonth,
      this.todayDate + 13
    ),
    globalAdmin: "false",
    outsideTrustedCountries: "MFA-Required",
    serviceAccountUsed: "false",
    serviceAccountUPN: "",
    alpha2CodeTrustedCountries: "",
  };
  private trustedCountries: Country = {
    name: "Schweiz",
    alpha2Code: "CH",
    alpha3Code: "CHE",
    numericCode: "756",
    callingCode: "+41",
  };

  constructor() {}
  getSetting() {
    return this.setting;
  }

  getCountry() {
    return this.trustedCountries;
  }
  addSetting(setting: Setting) {
    this.setting = setting;
  }
  addCountry(country: Country) {
    this.trustedCountries = country;
  }
}
