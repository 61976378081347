<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="text-center mb-4">
        <!-- Dynamically change Main heading from other component -->
        <h1 class="heading-main">
          {{headingMain}}
        </h1>
        <!-- Dynamically change Sub heading from other component -->
        <h4 class="heading-secondary">
          {{headingSecondary}}
        </h4>
      </div>
    </div>
  </div>
</div>
