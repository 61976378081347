import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// Model
import { Admin } from '../../model-form/admin.model';
import { Profile } from 'app/onboarding/model-form/profile.model';
// Services
import { AdminService } from '../../services/admin.service';
import { CommonService } from 'app/onboarding/services/common.service'; 
import { ContactsService } from 'app/onboarding/services/contacts.service';
import { ProfileService } from 'app/onboarding/services/profile.service';
import { CompanyService } from 'app/onboarding/services/company.service';




@Component({
  selector: 'nb-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit, OnDestroy {
  adminForm: FormGroup;
  admin: Admin ;
  profile: Profile;
  licensedUsers: number = 0;
  nextButton = false;

 

  constructor(private adminService: AdminService, 
    private profileService: ProfileService,
    private commonService: CommonService,
    private contactService: ContactsService,
    private companyService: CompanyService) {}

  ngOnInit(): void {
    // Set the next button disabled
    this.commonService.setValue(false);
    this.licensedUsers = this.companyService.getCompany().licensedUsers;

    //get data from adminservice if the user goes back to this page
    this.admin = this.adminService.getAdmin();

    // Admin Form group for admin details
    this.adminForm = new FormGroup({
      firstName: new FormControl(this.admin.firstName, Validators.required),
      companyName: new FormControl(this.admin.companyName, Validators.required),
      lastName: new FormControl(this.admin.lastName, Validators.required),
      email: new FormControl(this.admin.email,
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,9}$'),
        ]
        ),
    });

    // IF the admin form is valid set the next button enable
    this.enableNext()
}
// Set the next button enabled or disabled 
enableNext(){
  this.adminForm.statusChanges.subscribe(
    result => {if(result === 'VALID'){
      this.commonService.setValue(true);
      this.nextButton = true

    }
    else{
      this.commonService.setValue(false);
      this.nextButton = false
    }
  }
  );
}

// store the admin data to the AdminService
  onSubmit() {
    this.adminService.addAdmin(this.adminForm.value, this.licensedUsers);
    this.profile = this.profileService.getProfile()
    this.admin = this.adminService.getAdmin();
    this.contactService.addContacts(this.admin, this.profile);
  }

  ngOnDestroy(): void {
    
    // call data on page destroys
    this.onSubmit();

    // set the next button to disable
    this.commonService.setValue(false);
  }

}
