<h2 class="d-inline" align="start" mat-dialog-title>{{ "signError.cancelError.head" | translate }}</h2>
<mat-dialog-actions  align="end">
  <button  class="btn btn-close" mat-dialog-close></button>
</mat-dialog-actions>
<mat-dialog-content class="mat-typography">
  <p>{{ "signError.cancelError.p1" | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="btn btn-outline-danger mr-4" mat-dialog-close>Close</button>
</mat-dialog-actions>
