<nb-header
  headingSecondary="{{ 'admin.subtitle' | translate }}"
  headingMain="{{ 'admin.head' | translate }}"
></nb-header>
<div class="container">
  <div class="row">
    <div class="col-12 height-80">
      <div class="row">
        <div class="col-6 margin-top col-xs-12 form-admin">

          <!-- Form group for admin -->
          <form
            [formGroup]="adminForm"
            (ngSubmit)="onSubmit()"
            class="example-form mt-4"
          >

          <!-- Admin form First name -->
            <mat-form-field
              class="example-full-width d-block mb-4"
              appearance="outline"
            >
              <mat-label>{{ "admin.body.firstName" | translate }}</mat-label>
              <mat-icon matPrefix>supervisor_account</mat-icon>
              <input
                type="text"
                matInput
                placeholder="John"
                formControlName="firstName"
              />
              <mat-error
                *ngIf="adminForm.controls.firstName.hasError('required')"
              >
                {{ "admin.subtitle" | translate }}
                <strong>{{ "form.required" | translate }}</strong>
              </mat-error>
            </mat-form-field>


            <!-- Admin form Last name -->
            <mat-form-field
              class="example-full-width d-block mb-4"
              appearance="outline"
            >
              <mat-label>{{ "admin.body.lastName" | translate }}</mat-label>
              <mat-icon matPrefix>supervisor_account</mat-icon>
              <input
                type="text"
                matInput
                placeholder="Doe"
                formControlName="lastName"
              />
              <mat-error
                *ngIf="adminForm.controls.lastName.hasError('required')"
              >
                {{ "admin.body.lastName" | translate }}
                <strong>{{ "form.required" | translate }}</strong>
              </mat-error>
            </mat-form-field>

            <!-- Admin form Email -->
            <mat-form-field
              class="example-full-width d-block"
              appearance="outline"
            >
              <mat-label>Email</mat-label>
              <mat-icon matPrefix>email</mat-icon>
              <input
                type="email"
                matInput
                placeholder="johnDoe@email.com"
                formControlName="email"
              />
              <mat-error
                *ngIf="
                  adminForm.controls.email.hasError('email') &&
                  !adminForm.controls.email.hasError('required')
                "
              >
                {{ "form.email-valid" | translate }}
              </mat-error>
              <mat-error *ngIf="adminForm.controls.email.hasError('required')">
                {{ "form.email-valid" | translate }}
                <strong>{{ "form.required" | translate }}</strong>
              </mat-error>
            </mat-form-field>

            <!-- Admin form Company Name -->
            <mat-form-field
              class="example-full-width d-block mb-4"
              appearance="outline"
            >
              <mat-label>{{ "admin.body.companyName" | translate }}</mat-label>
              <mat-icon matPrefix>supervisor_account</mat-icon>
              <input
                type="text"
                matInput
                placeholder="Your Company Name"
                formControlName="companyName"
              />
              <mat-error
                *ngIf="adminForm.controls.companyName.hasError('required')"
              >
                {{ "admin.subtitle" | translate }}
                <strong>{{ "form.required" | translate }}</strong>
              </mat-error>
            </mat-form-field>

          </form>
          <!-- Form end -->
        </div>

        <!-- Admin png image -->
        <div class=" col-6 col-xs-12 admin-img-container d-flex align-items-center justify-content-center">
                <img src="../../../../assets/img/page-img/admin.png" alt="" class="img-fluid">
        </div>
        <!-- Admin png image end -->

      </div>
    </div>
  </div>
</div>
