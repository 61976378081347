// Base imports
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveComponentModule } from '@ngrx/component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgChartsModule } from 'ng2-charts';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { loginRequest, msalConfig } from './auth-config';

// Translate import for ngx-translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// MSAL import
import { MsalApplicationModule } from './module/msal-application.module';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';

// Material module import
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';

// Component module import
import { AppRoutingModule } from './module/app-routing.module';
import { AppComponent } from './app.component';

import { HeaderComponent } from './onboarding/components/header/header.component';
import { TenantOverviewComponent } from './onboarding/components/tenant-overview/tenant-overview.component';
import { AdminComponent } from './onboarding/components/admin/admin.component';
import { SettingsComponent } from './onboarding/components/settings/settings.component';
import { TenantDetailComponent } from './onboarding/components/tenant-overview/tenant-detail/tenant-detail.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FinishComponent } from './onboarding/components/finish/finish.component';
import { SubmitComponent } from './onboarding/components/submit/submit.component';
import { SecureScoreComponent } from './onboarding/components/tenant-overview/secure-score/secure-score.component';
import { SigninCancelComponent } from './onboarding/components/signin-error/signin-cancel/signin-cancel.component';
import { SigninNotAdminComponent } from './onboarding/components/signin-error/signin-not-admin/signin-not-admin.component';
import { AppPermissionComponent } from './onboarding/components/app-permission/app-permission.component';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';



// Translate function to load data from assets locales either de.json or en.json
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locales/', '.json');
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read"]);
  protectedResourceMap.set("https://graph.microsoft.com/beta/users", ["user.read"]);
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/organization", ["Directory.ReadWrite.All"]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/subscribedSkus', ['Directory.ReadWrite.All']);
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/users", ["user.read"]);
  protectedResourceMap.set("https://graph.microsoft.com/beta/reports/authenticationMethods/usersRegisteredByFeature", ["AuditLog.Read.All"]);
  protectedResourceMap.set("https://graph.microsoft.com/beta/security/secureScores",["SecurityEvents.Read.All"]);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Popup,
    authRequest: loginRequest,
  };
}

@NgModule({
  declarations: [ HeaderComponent, AppComponent, AppPermissionComponent, TenantOverviewComponent, AdminComponent, SettingsComponent, TenantDetailComponent, SubmitComponent, FinishComponent, SecureScoreComponent, SigninCancelComponent, SigninNotAdminComponent],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    ReactiveComponentModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    NgChartsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonToggleModule,
    MatSelectCountryModule,
    MatCardModule,
    MatRadioModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ScrollingModule,
    MatSelectCountryModule.forRoot('de'), // you can use 'br' | 'de' | 'en' | 'es' | 'fr' | 'hr' | 'it' | 'nl' | 'pt' --> MatSelectCountrySupportedLanguages
    // MsalApplicationModule.forRoot('/api/env/'),
    MsalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    })
  ],
   providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    // AppconfigService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
