import { Injectable } from "@angular/core";
import { Admin } from "../model-form/admin.model";
import { Contacts } from "../model-form/contacts.model";
import { Profile } from "../model-form/profile.model";

@Injectable({
  providedIn: "root",
})
export class ContactsService {
  private contacts: Contacts = {
    companyProfile: {
      firstName: "",
      companyName: "",
      lastName: "",
      email: "",
      licensedUsers: 0,
    },
    adminProfile: {
      givenName: "",
      surname: "",
      userPrincipalName: "",
    },
  };

  constructor() {}
  getContacts() {
    return this.contacts;
  }
  addContacts(admin: Admin, profile:Profile) {
    this.contacts.companyProfile = admin;
    this.contacts.adminProfile = profile;
  }
}
