import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'app/onboarding/services/common.service'; 

import {  Subscription } from 'rxjs';

const GRAPH_ENDPOINT_MFA_COUNT = "https://graph.microsoft.com/beta/security/secureScores"
@Component({
  selector: 'nb-secure-score',
  templateUrl: './secure-score.component.html',
  styleUrls: ['./secure-score.component.scss']
})
export class SecureScoreComponent implements OnInit, OnDestroy {
  //Variables//
  percent:number; //secure score percentage
  translateSubscribe: Subscription //translate for tour guide.

  steptranslate:any = {}
  // icons for checkmark and cross  
  pass = "../../.././../assets/svg/pass.svg";
  fail = "../../.././../assets/svg/fail.svg";
  attection = "../../.././../assets/svg/attention.svg";

 
  isLoading: boolean = true;// if the data is loading

  secureScores:any =[]; //secure score data
  sstranslate:any[] = [
    {},{},{},{},{},{},{},{}
  ] //secure score description translation


  percentToColor(percent:number): string {
    if (percent < 1.00) {
      return "#fe5454"; //red
    } else if (percent < 50.00) {
      return "#ffa600"; // orange
    } else if (percent >= 99) {
      return "#00FF00";// green
    }
    return "#ffa600"; //orrange
  }

  constructor( private http:HttpClient, private commonService: CommonService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.getSSTranslate();
    this.getSecureScore();
  }


  // getSecureScore() {
  //   this.http.get<any>(GRAPH_ENDPOINT_MFA_COUNT).subscribe({
  //     next: (response) => {
  //       const adminMFAV2 = response.value[0].controlScores.find(
  //         (mfa:any) =>
  //         mfa.controlName === "AdminMFAV2",
  //       );
  //       const oneAdmin = response.value[0].controlScores.find(
  //         (mfa:any) =>
  //         mfa.controlName === "OneAdmin",
  //       );
  //       const roleOverlap = response.value[0].controlScores.find(
  //         (mfa:any) =>
  //         mfa.controlName === "RoleOverlap",
  //       );const blockLegacyAuthentication = response.value[0].controlScores.find(
  //         (mfa:any) =>
  //         mfa.controlName === "BlockLegacyAuthentication",
  //       );const mFARegistrationV2 = response.value[0].controlScores.find(
  //         (mfa:any) =>
  //         mfa.controlName === "MFARegistrationV2",
  //       );const selfServicePasswordReset = response.value[0].controlScores.find(
  //         (mfa:any) =>
  //         mfa.controlName === "SelfServicePasswordReset",
  //       );const tLSDeprecation = response.value[0].controlScores.find(
  //         (mfa:any) =>
  //         mfa.controlName === "TLSDeprecation",
  //       );


  //         this.secureScores[0] = adminMFAV2;
  //         this.secureScores[1] = oneAdmin;
  //         this.secureScores[2] = roleOverlap;
  //         this.secureScores[3] = blockLegacyAuthentication;
  //         this.secureScores[4] = mFARegistrationV2;
  //         this.secureScores[5] = selfServicePasswordReset;
  //         this.secureScores[6] = tLSDeprecation;
  //         this.secureScores[0].name = "MFA for Admin (AdminMFAV2)";
  //         this.secureScores[1].name = "Existing multiple Admins?";
  //         this.secureScores[2].name = "Limited Admin Roles assigned";
  //         this.secureScores[3].name = "Block Legacy Protocols";
  //         this.secureScores[4].name = "Require MFA for all Users";
  //         this.secureScores[5].name = "Enable Self Service Password Reset";
  //         this.secureScores[6].name = "Disable old Encryption Protocols";

  //         this.isLoading = false;
  //         this.commonService.setValue(true);



  //     },
  //     error: (error) =>
  //       this.isLoading = true
  //   })

  // }
  // secure score find control name function
  findControl(controlMfa, index:number, response){
    const controlMfas = response.value[0].controlScores.find(
      (mfa:any) =>
      mfa.controlName === controlMfa,
    )

if(controlMfas == undefined || controlMfas.controlState == undefined ){
return null
}
else{
  this.secureScores[index] = controlMfas;
  this.secureScores[index].title = this.sstranslate[index].title
  this.secureScores[index].detail = this.sstranslate[index].text
}
}

// call graph and find the control name function
  getSecureScore() {
    this.http.get<any>(GRAPH_ENDPOINT_MFA_COUNT).subscribe({
      next: (response) => {
          this.findControl("AdminMFAV2",0, response)
          this.findControl("OneAdmin",1,response)
          this.findControl("RoleOverlap",2,response)
          this.findControl("BlockLegacyAuthentication",3,response)
          this.findControl("MFARegistrationV2",4,response)
          this.findControl("SelfServicePasswordReset",5,response)
          this.findControl("TLSDeprecation",6,response)
          this.isLoading = false;
          this.commonService.setValue(true);
      },
      error: (error) =>
        this.isLoading = true
    })
  }

// translation for secure score description
    getSSTranslate() {
      this.translateSubscribe = this.translate
        .stream("secure-score.title.mfa-for-admin")
        .subscribe((text: string) => {
          this.sstranslate[0].title = text;
        });
        this.translateSubscribe = this.translate
        .stream("secure-score.description.mfa-for-admin")
        .subscribe((text: string) => {
          this.sstranslate[0].text = text;
        });
        this.translateSubscribe = this.translate
        .stream("secure-score.title.multiple-admins")
        .subscribe((text: string) => {
          this.sstranslate[1].title = text;
        });
        this.translateSubscribe = this.translate
        .stream("secure-score.description.multiple-admins")
        .subscribe((text: string) => {
          this.sstranslate[1].text = text;
        });
        this.translateSubscribe = this.translate
        .stream("secure-score.title.limited-admin")
        .subscribe((text: string) => {
          this.sstranslate[2].title = text;
        });
        this.translateSubscribe = this.translate
        .stream("secure-score.description.limited-admin")
        .subscribe((text: string) => {
          this.sstranslate[2].text = text;
        });
        this.translateSubscribe = this.translate
        .stream("secure-score.title.block-legacy")
        .subscribe((text: string) => {
          this.sstranslate[3].title = text;
        });
        this.translateSubscribe = this.translate
        .stream("secure-score.description.block-legacy")
        .subscribe((text: string) => {
          this.sstranslate[3].text = text;
        });
        this.translateSubscribe = this.translate
        .stream("secure-score.title.require-mfa")
        .subscribe((text: string) => {
          this.sstranslate[4].title = text;
        });
        this.translateSubscribe = this.translate
        .stream("secure-score.description.require-mfa")
        .subscribe((text: string) => {
          this.sstranslate[4].text = text;
        });
        this.translateSubscribe = this.translate
        .stream("secure-score.title.enable-sspr")
        .subscribe((text: string) => {
          this.sstranslate[5].title = text;
        });
        this.translateSubscribe = this.translate
        .stream("secure-score.description.enable-sspr")
        .subscribe((text: string) => {
          this.sstranslate[5].text = text;
        });
        this.translateSubscribe = this.translate
        .stream("secure-score.title.disable-encryption")
        .subscribe((text: string) => {
          this.sstranslate[6].title = text;
        });
        this.translateSubscribe = this.translate
        .stream("secure-score.description.disable-encryption")
        .subscribe((text: string) => {
          this.sstranslate[6].text = text;
        });
    }
  

  ngOnDestroy(): void {
    // disable next button on page destroys
    this.commonService.setValue(false);
}

}
