<div class="row" *ngIf="isLoading">
  <div class="col-12 ">
    <div class="row height-60">
      <div class=" col-12 admin-img-container d-flex align-items-center justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</div>
<div class="row mt-4">
  </div>
  <div class="px-5">
    <div *ngIf="!isLoading">
      <div class="row scroll">
        <div *ngFor="let score of secureScores; index as i;">
          <div class="col-12 mb-2">
            <div class="card" style="max-width: 100%;" >
              <div class="row g-0">
                <div class="col-md-2 col-4 col-lg-1 d-flex align-items-center justify-content-center" style="min-height:10%">
                  <!-- secure score green image icon -->
                    <img  *ngIf="score.scoreInPercentage >= 100"
                      src="../../../../../assets/svg/pass.svg"
                    />
                    <!-- secure score red image icon -->
                    <img *ngIf="score.scoreInPercentage <= 1"
                      src="../../../../../assets/svg/fail.svg"
                    />
                    <!-- secure score yellow image icon -->
                    <img *ngIf="score.scoreInPercentage > 1 && score.scoreInPercentage < 50"
                      src="../../../../../assets/svg/atention.svg"
                    />
                </div>
                <!-- secure score name title -->
                <div class="col-md-3 col-8 col-lg-3 d-flex align-items-center ml-1"  [class]="'element'+i" style="min-height:10%">
                  <h5 class="card-title">{{score.title}}</h5>
                </div>
                <div class="col-md-7 col-12 col-lg-8">
                  <!-- secure score description -->
                  <div class="card-body">
                    <p class="card-text">{{score.detail}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  