import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { MsalService } from "@azure/msal-angular";
import {
  AuthenticationResult
} from "@azure/msal-browser";
import { ChartData, ChartEvent, ChartType } from 'chart.js';
// model
import { Profile } from "../../../model-form/profile.model";
import { Company } from "app/onboarding/model-form/company.model";
// services
import { ProfileService } from "../../../services/profile.service";
import { TenantIdService } from "../../../services/tenant-id.service";
import { CommonService } from "app/onboarding/services/common.service"; 
import { CompanyService } from "app/onboarding/services/company.service";

// graph-endpoint
const GRAPH_ENDPOINT_ME = "https://graph.microsoft.com/v1.0/me";
const GRAPH_ENDPOINT_ORGANIZATION ="https://graph.microsoft.com/v1.0/organization";
const GRAPH_ENDPOINT_MFA_PREMIUM ='https://graph.microsoft.com/v1.0/subscribedSkus';
const GRAPH_ENDPOINT_MFA = "https://graph.microsoft.com/beta/users";
const GRAPH_ENDPOINT_MFA_COUNT =
  "https://graph.microsoft.com/beta/reports/authenticationMethods/usersRegisteredByFeature";

  // set Models
type TenantType = {
  value?: { 0?: { id: string } };
}; //tenant id model
 

@Component({
  selector: "nb-tenant-detail",
  templateUrl: "./tenant-detail.component.html",
  styleUrls: ["./tenant-detail.component.scss"],
})
export class TenantDetailComponent implements OnInit, OnDestroy {
  // no of licensed users model
  company:Company = {
    licensedUsers: 0
  }

 // Variables //
  public doughnutChartLabels: string[] = [ 'MFA Enabled', 'MFA Not Enabled', 'MFA Not Capable' ]; //doughnut chart label
  public doughnutChartData: ChartData<'doughnut'>; //doughnut chart data MFA
  public doughnutChartData2: ChartData<'doughnut'>; //doughnut chart data SSPR
  public doughnutChartType: ChartType = 'doughnut'; //doughnut chart type
  usersValue: number; //number of users

  numOfLicensedUsers: number = 0;
  numOfBusBasicUsers: number = 0;
  numOfBusStandUsers: number = 0;
  userCount: number = 0;

  skuid: any = [
    'efccb6f7-5641-4e0e-bd10-b4976e1bf68e',
    'e97c048c-37a4-45fb-ab50-922fbf07a370',
    'e823ca47-49c4-46b3-b38d-ca11d5abe3d2',
    'e2be619b-b125-455f-8660-fb503e431a5d',
    'd61d61cc-f992-433f-a577-5bd016037eeb',
    'cd2925a3-5076-4233-8931-638a8c94f773',
    'cbdc14ab-d96c-4c30-b9f4-6ada7cdc1d46',
    'ca9d1dd9-dfe9-4fef-b97c-9bc1ea3c3658',
    'c793db86-5237-494e-9b11-dcd4877c2c8c',
    'c42b9cae-ea4f-4ab7-9717-81576235ccac',
    'c2ac2ee4-9bb1-47e4-8541-d689c7e83371',
    'b05e124f-c7cc-45a0-a6aa-8cf78c946968',
    'aedfac18-56b8-45e3-969b-53edb4ba4952',
    '8a180c2b-f4cf-4d44-897c-3d32acc4a60b',
    '84a661c4-e949-4bd2-a560-ed7766fcaf2b',
    '81441ae1-0b31-4185-a6c0-32b6b84d419f',
    '7cfd9a2b-e110-4c39-bf20-c6a3f36a3121',
    '66b55226-6b4f-492c-910c-a3b7a3c9d993',
    '6070a4c8-34c6-4937-8dfb-39bbc6397a60',
    '50f60901-3181-4b75-8a2c-4c8e4c1d5a72',
    '4b590615-0888-425a-a965-b3bf7789848d',
    '46c119d4-0379-4a9d-85e4-97c66d3f909e',
    '44575883-256e-4a79-9da4-ebe9acabe2b2',
    '31d57bc7-3a05-4867-ab53-97a17835a411',
    '2a914830-d700-444a-b73c-e3f31980d833',
    '1aa94593-ca12-4254-a738-81a5972958e8',
    '18250162-5d87-4436-a834-d795c15c80f3',
    '078d2b04-f1bd-4111-bbd4-b4b1b354cef4',
    '06ebc4ee-1bb5-47dd-8120-11324bc54e06',
    '05e9a617-0261-4cee-bb44-138d3ef5d965',

  ];
  skuidBusBasic: any = [
    '3b555118-da6a-4418-894f-7df1e2096870',
    'dab7782a-93b1-4074-8bb1-0e61318bea0b'
  ];
  skuidBusStand: any = [
    'f245ecc8-75af-4f8e-b61f-27d8114de5f3',
    'ac5cef5d-921b-4f97-9ef3-c99076e5470f',
  ] //Skuid for check active premium users

  // events if user clicks on chart
  public chartClicked({ event, active }: { event: ChartEvent, active: {}[] }): void {
  }

  public chartHovered({ event, active }: { event: ChartEvent, active: {}[] }): void {
  }

  // loading  variable
  isLoading = true;

  // tenant-profile
  profile: Profile;
  profilePost: Profile;
  tenant!: TenantType;
  tenantID: string;

  // users
  usersList: {};
  usersCount: number;

  // mfa percentage
  mfaEnabledCount: number;
  mfaCapableCount: number;

  // Chart data for Mfa
  mfaEnabledPercentage: string = "";
  mfaCapablePercentage: string = "";
  notMfaPercentage: string = "";

  // Chart data for SSPR
  ssprEnabledPercentage: string = "";
  ssprCapablePercentage: string = "";
  notSsprPercentage: string = "";

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private authService: MsalService,
    private profileService: ProfileService,
    private iDTenant: TenantIdService,
    private companyService: CompanyService,
    private commonService: CommonService
  ) {}



  ngOnInit(): void {
    // get token for MSal
    this.authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result) {
          this.authService.instance.setActiveAccount(result.account);
        }
      },
      error: (error) => console.log(error),
    });
    this.getProfile();
  }

  // public bubbleChartData: ChartData<'bubble'> = {
  //   labels: [],
  //   datasets: [ {
  //     data: [
  //       { x: 10, y: 10, r: 10 },
  //       { x: 15, y: 5, r: 15 },
  //       { x: 26, y: 12, r: 23 },
  //       { x: 7, y: 8, r: 8 },
  //     ],
  //     label: 'Series A',
  //     backgroundColor: [
  //       'red',
  //       'green',
  //       'blue',
  //       'purple',
  //       'yellow',
  //       'brown',
  //       'magenta',
  //       'cyan',
  //       'orange',
  //       'pink'
  //     ],
  //     borderColor: 'blue',
  //     hoverBackgroundColor: 'purple',
  //     hoverBorderColor: 'red',
  //   } ]
  // };

  // Get data for Mfa chart and SSPR chart function
  getMfaAuthenticationMethods() {
    this.http.get<any>(GRAPH_ENDPOINT_MFA_COUNT).subscribe(
      (response) => {

        // Mfa-Count
        //MFA-Count
        const mfaEnabledCount = response.userRegistrationFeatureCounts.find(
          (feature: any) => feature.feature === 'mfaCapable'
        ).userCount;
        this.mfaEnabledPercentage =
          ((mfaEnabledCount / this.usersCount) * 100).toFixed().toString() +
          '%';
          const mfaCapableCount = this.numOfLicensedUsers;
        this.mfaCapablePercentage =
          ((mfaCapableCount / this.usersCount) * 100).toFixed().toString() +
          '%';
        const notMfaUsers = this.usersCount - this.numOfLicensedUsers;
        this.notMfaPercentage =
          ((notMfaUsers / this.usersCount) * 100).toFixed().toString() + '%';

        // const mfaEnabledCount = response.userRegistrationFeatureCounts.find(
        //   (feature) => feature.feature === "mfaCapable"
        // ).userCount; //number of Mfa enabled Users
        // const mfaCapableCount = this.numOfLicensedUsers; //number of Mfa capable Users
        // this.companyService.addCompany(this.company); //add no of licensed users to service
        // const notMfaUsers = this.usersCount - this.numOfLicensedUsers //number of not Mfa enabled Users

          // Mfa Data to without decimal and string
        // this.mfaEnabledPercentage = ((mfaEnabledCount / this.usersCount) * 100).toFixed(1).toString() + "%";
        // this.mfaCapablePercentage = ((mfaCapableCount / this.usersCount) * 100).toFixed(1).toString() + "%";
        // this.notMfaPercentage = (100 - (notMfaUsers / this.usersCount) * 100).toFixed(1).toString() + "%";

        // chart for MFA
        this.doughnutChartData = {
          datasets: [
            {
              data: [  mfaEnabledCount,  
                 mfaEnabledCount - this.numOfLicensedUsers , 
                 mfaCapableCount < this.usersCount ? this.usersCount - mfaCapableCount: 0, ],
                 backgroundColor: [
                  'green',
                  'red',
                  'blue'
                ],
            }
          ],
          
        };
       
      

        // SSPR-Count
        const ssprEnabledCount = response.userRegistrationFeatureCounts.find(
          (feature) => feature.feature === "ssprEnabled"
        ).userCount;//number of SSPR enabled Users
        const ssprCapableCount = response.userRegistrationFeatureCounts.find(
          (feature) => feature.feature === "ssprCapable"
        ).userCount;//number of SSPR capable Users

        // chart for SSPR
        this.doughnutChartData2 = {
          datasets: [
            {
              data: [  ssprEnabledCount,  
                ssprCapableCount, 
                this.usersCount - (ssprCapableCount + ssprEnabledCount) ],
                 backgroundColor: [
                  'green',
                  'red',
                  'blue'
                ],
            }
          ],
          
        };
        // SSPR Data to without decimal and string
        this.ssprEnabledPercentage = ((ssprEnabledCount / this.usersCount) * 100).toFixed(1).toString() + "%";
        this.ssprCapablePercentage = ((ssprCapableCount / this.usersCount) * 100).toFixed(1).toString() + "%";
        this.notSsprPercentage = (((this.usersCount - (ssprEnabledCount + ssprCapableCount)) / this.usersCount) * 100).toFixed(1).toString() + "%";

        // data loaded set loading to false
        this.isLoading = false;
        // enable next button
        this.commonService.setValue(true);
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
  

  // get number of users details from tenant function
  getUsers() {
    this.http.get<any>(GRAPH_ENDPOINT_MFA).subscribe(
      (response) => {
        this.usersCount = response.value.length;
        this.getPremiumUsers()
        this.getMfaAuthenticationMethods();
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
  // get number of licenced users from tenant
  getPremiumUsers() {
    this.http.get<any>(GRAPH_ENDPOINT_MFA_PREMIUM).subscribe({
      next: (response) => {
        this.usersValue = response.value;
        this.numOfLicensedUsers = this.getLicensedUsersCount(this.usersValue);
        // console.log(this.numOfLicensedUsers)
        // console.log("licensed")
        this.numOfBusBasicUsers = this.getBusBasicUsers(this.usersValue);
        this.numOfBusStandUsers = this.getBusStandUsers(this.usersValue);
      },
      error: (error) => error,
    });
  }

  getBusBasicUsers(users:any): number {
    let count: number = 0;
    this.skuidBusBasic.forEach((skuid) => {
      users.forEach((plan) => {
        if (plan.skuId === skuid) {
          if (plan.capabilityStatus === 'Enabled') {
            count = count + plan.consumedUnits;
          }
        }
      })
    });
    return count
  };
  getBusStandUsers(users:any): number {
    let count: number = 0;
    this.skuidBusStand.forEach((skuid) => {
      users.forEach((plan) => {
        if (plan.skuId === skuid) {
          if (plan.capabilityStatus === 'Enabled') {
            count = count + plan.consumedUnits;
          }
        }
      })
    });
    return count
  };
  getLicensedUsersCount(users: any): number {
    let count: number = 0;
    this.skuid.forEach((skuid) => {
      users.forEach((plan) => {
        if (plan.skuId === skuid) {
          if (plan.capabilityStatus === 'Enabled') {
            count = count + plan.consumedUnits;
          }
        }
      });
    });
    return count;
  }
// get tenant ID of the tenant
  getTenantId() {
    this.http.get(GRAPH_ENDPOINT_ORGANIZATION).subscribe((tenant) => {
      this.tenant = tenant;
      this.iDTenant.addTenantId(this.tenant.value[0].id);
      this.getUsers();
    });
  }

// get profile detail of the user
  getProfile() {
    this.http.get(GRAPH_ENDPOINT_ME).subscribe((profile: Profile) => {
      this.profile = profile;

      this.addProfile();
      this.getTenantId();
    });
  }

// add the profile detail of the users to the profile service
  addProfile() {
    var tenant;
    var ProfileData: Profile;

    ProfileData = {
      givenName: this.profile.givenName,
      surname: this.profile.surname,
      userPrincipalName: this.profile.userPrincipalName,
    };
    this.profileService.addProfile(ProfileData);
    tenant = this.profileService.getProfile();
  }
  
  // destroy data on page destroys
  ngOnDestroy(): void {
    this.commonService.setValue(false);
    this.addProfile();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
