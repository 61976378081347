<div class="container p-0">
  <div class="row" *ngIf="isLoading">
    <div class="col-12 ">
      <div class="row height-60">
        <div class=" col-12 admin-img-container d-flex align-items-center justify-content-center">
          <!-- loading div -->
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading">

    <div class="summary-wrapper">
      <div class="scroll">
        <div class="row margin-right-0">
          <div class="col-xl-8 col-md-12 col-sm-12 my-auto">
            <div class="col-12">
              <div class="m-4">
                <div class="card mx-auto">
                  <!-- Mfa page title -->
                  <h5 class="card-title mx-auto p-2">{{ 'tenant.body.chart-1.head' | translate }}</h5>
                  <!-- MFA Chart section - 1-->
                  <div class="row ">
                    <div class="col-md-5 mx-auto  my-auto p-4">
                      <div class="chart-wrapper mx-auto">
                        <!-- percentage betwween the chart -->
                        <div class="doughnut-percentage" style="font-size: 2vw;">
                          {{mfaCapablePercentage}}
                        </div>
                        <!-- Mfa chart -->
                        <canvas baseChart [data]="doughnutChartData" [options]="{cutout: 80, aspectRatio: 1}"
                          [legend]="false" [type]="doughnutChartType">
                        </canvas>
                      </div>
                    </div>
                    <div class="col-md-7 my-auto cardBody">
                      <div class="card-body  offset-md-2">
                        <div class="text-muted card-text">
                          <!-- Mfa Legend text -->
                          <div class="doughnut-description chart-table" *ngIf="mfaCapablePercentage !== ''">
                            <span class="one">{{mfaEnabledPercentage}} {{ 'tenant.body.chart-1.subtitle.1' | translate
                              }}</span>
                            <span class="two">{{mfaCapablePercentage}} {{ 'tenant.body.chart-1.subtitle.2' | translate
                              }}</span>
                            <span class="three">{{notMfaPercentage}} {{ 'tenant.body.chart-1.subtitle.3' | translate
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- SSPR chart -2 -->
            <div class="col-12">
              <div class="m-4">
                <div class="card mx-auto">
                  <h5 class="card-title mx-auto p-2">{{ 'tenant.body.chart-2.head' | translate }}</h5>
                  <div class="row ">
                    <div class="col-md-5 mx-auto my-auto p-4">
                      <div class="chart-wrapper mx-auto">
                        <!-- percentage betwween the chart -->
                        <div class="doughnut-percentage" style="font-size: 2vw;">
                          {{ssprCapablePercentage}}
                        </div>
                        <!-- SSPR chart -->
                        <canvas baseChart [data]="doughnutChartData2" [options]="{cutout: 80, aspectRatio: 1}"
                          [legend]="false" [type]="doughnutChartType">
                        </canvas>
                      </div>
                    </div>
                    <div class="col-md-7 my-auto cardBody">
                      <div class="card-body  offset-md-2">
                        <div class="text-muted card-text">
                          <!-- SSPR Legend -->
                          <div class="doughnut-description chart-table" *ngIf="ssprCapablePercentage !== ''">
                            <span class="one">{{ssprCapablePercentage}} {{ 'tenant.body.chart-2.subtitle.1' | translate
                              }}</span>
                            <span class="two">{{ssprEnabledPercentage}} {{ 'tenant.body.chart-2.subtitle.2' | translate
                              }}</span>
                            <span class="three">{{notSsprPercentage}} {{ 'tenant.body.chart-2.subtitle.3' | translate
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-12 col-sm-12 mx-auto my-auto">
            <!-- Total number of users -->
            <div class="licensed-users">
              {{numOfLicensedUsers}}
              <div class="doughnut-description" *ngIf="numOfLicensedUsers">
                {{ 'tenant.body.basic.head' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>