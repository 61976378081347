import { Country } from "@angular-material-extensions/select-country";
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Subscription } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { builtInButtons } from "app/onboarding/utils/data";
import { TooltipPosition } from "@angular/material/tooltip";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";

import { SettingService } from "../../services/setting.service";
import { CommonService } from "app/onboarding/services/common.service"; 
import { ShepherdService } from "angular-shepherd";
import { TranslateService } from "@ngx-translate/core";

import { StatusService } from "app/onboarding/services/status.service";
import { Setting } from "../../model-form/setting.model";
import { Status } from "app/onboarding/model-form/status.model";

@Component({
  selector: "nb-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterContentInit
{
  // Variable //

  events: {} = {}; //Data from date picker
  translateSubscribe: Subscription; //translation subscription for tour
  steptranslate: any = []; // for tour translation
  useDevice: boolean = false; //service account option
  //globalAdminSelected: boolean = false; //Global admin toggle
  ipSelected: boolean = false; //static ip toggle
  setting: Setting; //setting data model
  trustedCountries: Country; //country data model
  title = "select-country"; // title for tour
  positionOptions: TooltipPosition[] = [
    "after",
    "before",
    "above",
    "below",
    "left",
    "right",
  ]; // tour positioning on the page
  settingForm: FormGroup; //form group for setting
  position = new FormControl(this.positionOptions[0]); //form control for tour positioning

  // model
  status:Status = {
    registeredDate: new Date().toISOString(),
    activationDate: "",
    cancelationDate: "",
    token: localStorage.getItem('token')
  } //registration date model

  // set date for 14 days variable
  minDate: Date;
  maxDate: Date;

  constructor(
    private settingService: SettingService,
    private statusService: StatusService,
    private shepherdService: ShepherdService,
    private commonService: CommonService,
    private translate: TranslateService
  ) {
    // get current date of the registration
    const currentYear = new Date().getFullYear(); 
    const todayDate = new Date().getDate();
    const currentMonth = new Date().getMonth();
    this.minDate = new Date(currentYear, currentMonth, todayDate);
    this.maxDate = new Date(currentYear, currentMonth, todayDate + 13);
  }

  

  ngOnInit(): void {
    // add registered date to the service
    this.statusService.addStatus(this.status)

    // disable next button on page startup
    this.commonService.setValue(false);

    // get current country if the user goes back to the page
    this.trustedCountries = this.settingService.getCountry();
    // get setting data if the user goes back to the page
    this.setting = this.settingService.getSetting();

    // setting form group
    this.settingForm = new FormGroup({
      trustedCountries: new FormControl(
        this.trustedCountries,
        Validators.required
      ),
      staticIPAddressUsed: new FormControl(this.setting.staticIPAddressUsed, Validators.required),
      staticIPAddress: new FormControl(this.setting.staticIPAddress),
      assignmentDate: new FormControl(this.setting.assignmentDate, Validators.required),
      globalAdmin: new FormControl(
        this.setting.globalAdmin,
        Validators.required
      ),
      outsideTrustedCountries: new FormControl(
        this.setting.outsideTrustedCountries,
        Validators.required
      ),
      serviceAccountUsed: new FormControl(
        this.setting.serviceAccountUsed,
        Validators.required
      ),
      serviceAccountUPN: new FormControl(this.setting.serviceAccountUPN),
      alpha2Code: new FormControl(this.trustedCountries.alpha2Code),
    });

    // if the setting form is valid set next button to enable
    this.settingForm.statusChanges.subscribe((result) => {
      if (result === "VALID") {
        this.commonService.setValue(true);
      } else {
        this.commonService.setValue(false);
      }
    });

    // setting form validate static ip address field if static ip is true
    // this.settingForm.get("staticIPAddressUsed").valueChanges.subscribe((value) => {
    //   const staticIPAddress = this.settingForm.get("staticIPAddress");
    //   if (value === "true") {
    //     staticIPAddress.setValidators([
    //       Validators.required,
    //       Validators.pattern(
    //         "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)"
    //       ),
    //     ]);
    //   } else {
    //     staticIPAddress.clearValidators();
    //   }
    //   staticIPAddress.updateValueAndValidity();
    // });

    this.settingForm.get("staticIPAddressUsed").valueChanges.subscribe((value) => {
      const ipAddress = this.settingForm.get("staticIPAddress");
      if (value === "true") {
        ipAddress.setValidators([
          Validators.required,
          Validators.pattern(
            "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)"
          ),
        ]);
      } else {
        ipAddress.clearValidators();
      }
      ipAddress.updateValueAndValidity();
    });

    // setting form validate service email address field if service account is true
    this.settingForm.get("serviceAccountUsed").valueChanges.subscribe((value) => {
      const automateEmail = this.settingForm.get("serviceAccountUPN");
      if (value === "true") {
        automateEmail.setValidators([
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ]);
      } else {
        automateEmail.clearValidators();
      }
      automateEmail.updateValueAndValidity();
    });
  }

  ngAfterContentInit() {
    // Translate the tour stps
    this.getTranslateNav();
  }
  ngAfterViewInit() {
    //start the tour steps
    setTimeout(() => {
      this.addShepherd();
      this.tourStart();
    }, 100);
  }
 
  // tour step function
  tourStart() {
    this.shepherdService.start();
  }
  //tour end function
  tourEnd() {
    this.shepherdService.cancel();
  }

  // add steps to the tour
  addShepherd() {
    this.shepherdService.addSteps([
      {
        id: "step1",
        attachTo: {
          element: ".second-element",
          on: "auto",
        },
        buttons: [builtInButtons.cancel, builtInButtons.next],

        classes: "shepherd-class-mine",
        highlightClass: "highlight",
        scrollTo: true,
        title: this.steptranslate.title1,
        text: [this.steptranslate.text1],
      },
      {
        id: "step2",
        attachTo: {
          element: ".second-element2",
          on: "auto",
        },
        buttons: [
          builtInButtons.cancel,
          builtInButtons.back,
          builtInButtons.next,
        ],
        classes: "custom-class-name-1 custom-class-name-2",
        highlightClass: "highlight",
        scrollTo: true,
        title: this.steptranslate.title1,
        text: [this.steptranslate.text7],
        showOn () {
            return document.querySelector('.second-element2') ? true : false
        },
      },
      {
        id: "step3",
        attachTo: {
          element: ".third-element",
          on: "auto",
        },
        buttons: [
          builtInButtons.cancel,
          builtInButtons.back,
          builtInButtons.next,
        ],
        classes: "custom-class-name-1 custom-class-name-2",
        highlightClass: "highlight",
        scrollTo: true,
        title: this.steptranslate.title2,
        text: [this.steptranslate.text2],
      },
      // {
      //   id: "step4",
      //   attachTo: {
      //     element: ".fourth-element",
      //     on: "auto",
      //   },
      //   buttons: [
      //     builtInButtons.cancel,
      //     builtInButtons.back,
      //     builtInButtons.next,
      //   ],
      //   classes: "custom-class-name-1 custom-class-name-2",
      //   highlightClass: "highlight",
      //   scrollTo: true,
      //   title: this.steptranslate.title3,
      //   text: [this.steptranslate.text3],
      // },
      {
        id: "step5",
        attachTo: {
          element: ".fifth-element",
          on: "auto",
        },
        buttons: [
          builtInButtons.cancel,
          builtInButtons.back,
          builtInButtons.next,
        ],
        classes: "custom-class-name-1 custom-class-name-2",
        highlightClass: "highlight",
        scrollTo: true,
        title: this.steptranslate.title4,
        text: [this.steptranslate.text4],
      },
      {
        id: "step6",
        attachTo: {
          element: ".sixth-element",
          on: "auto",
        },
        buttons: [
          builtInButtons.cancel,
          builtInButtons.back,
          builtInButtons.next,
        ],
        classes: "custom-class-name-1 custom-class-name-2",
        highlightClass: "highlight",
        scrollTo: true,
        title: this.steptranslate.title5,
        text: [
          `${this.steptranslate.text51}\n${this.steptranslate.text52}\n${this.steptranslate.text53}`,
        ],
      },
      {
        id: "step7",
        attachTo: {
          element: ".seventh-element",
          on: "auto",
        },
        buttons: [builtInButtons.back, builtInButtons.next, builtInButtons.cancel],
        classes: "custom-class-name-1 custom-class-name-2",
        highlightClass: "highlight",
        scrollTo: true,
        title: this.steptranslate.title6,
        text: [this.steptranslate.text6],
      },
      {
        id: "step8",
        attachTo: {
          element: ".seventh-element2",
          on: "auto",
        },
        buttons: [builtInButtons.back, builtInButtons.cancel],
        classes: "custom-class-name-1 custom-class-name-2",
        highlightClass: "highlight",
        scrollTo: true,
        title: this.steptranslate.title6,
        text: [this.steptranslate.text8],
        showOn () {
          return document.querySelector('.seventh-element2') ? true : false
      },
      },
    ]);
  }

  // Translation for tour guide
  getTranslateNav() {
    this.translateSubscribe = this.translate
      .stream("setting.body.toolTip.fixedIpAddress")
      .subscribe((text: string) => {
        this.steptranslate.text1 = text;
      });

    this.translateSubscribe = this.translate
      .stream("setting.body.toolTip.date")
      .subscribe((text: string) => {
        this.steptranslate.text2 = text;
      });

    this.translateSubscribe = this.translate
      .stream("setting.body.toolTip.globalAdmin")
      .subscribe((text: string) => {
        this.steptranslate.text3 = text;
      });
    this.translateSubscribe = this.translate
      .stream("setting.body.toolTip.country")
      .subscribe((text: string) => {
        this.steptranslate.text4 = text;
      });
    this.translateSubscribe = this.translate
      .stream("setting.body.toolTip.requestMfa.required")
      .subscribe((text: string) => {
        this.steptranslate.text51 = text;
      });
    this.translateSubscribe = this.translate
      .stream("setting.body.toolTip.requestMfa.blocked")
      .subscribe((text: string) => {
        this.steptranslate.text52 = text;
      });
    this.translateSubscribe = this.translate
      .stream("setting.body.toolTip.requestMfa.noRestrict")
      .subscribe((text: string) => {
        this.steptranslate.text53 = text;
      });
    this.translateSubscribe = this.translate
      .stream("setting.body.toolTip.automateDevice")
      .subscribe((text: string) => {
        this.steptranslate.text6 = text;
      });
    this.translateSubscribe = this.translate
      .stream("submit.fixedIp")
      .subscribe((text: string) => {
        this.steptranslate.title1 = text;
      });
    this.translateSubscribe = this.translate
      .stream("submit.date")
      .subscribe((text: string) => {
        this.steptranslate.title2 = text;
      });
    this.translateSubscribe = this.translate
      .stream("submit.globalAdmin")
      .subscribe((text: string) => {
        this.steptranslate.title3 = text;
      });
    this.translateSubscribe = this.translate
      .stream("submit.country")
      .subscribe((text: string) => {
        this.steptranslate.title4 = text;
      });
    this.translateSubscribe = this.translate
      .stream("submit.mfaRequest")
      .subscribe((text: string) => {
        this.steptranslate.title5 = text;
      });
    this.translateSubscribe = this.translate
      .stream("submit.automatDevice")
      .subscribe((text: string) => {
        this.steptranslate.title6 = text;
      });
    this.translateSubscribe = this.translate
      .stream("setting.body.toolTip.fixedIpYes")
      .subscribe((text: string) => {
        this.steptranslate.text7 = text;
      });
    this.translateSubscribe = this.translate
      .stream("setting.body.toolTip.automateDeviceYEs")
      .subscribe((text: string) => {
        this.steptranslate.text8 = text;
      });
    this.translateSubscribe.unsubscribe;
  }

  // Get static Ip is yes or no
  get valueChange(): any {
    return this.ipSelected;
  }

  // get data from date for setup
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events = `${type}: ${event.value}`;
  }

 //set Static Ip true or false
  selectionChangedIpT() {
    this.ipSelected = true;
  }
  selectionChangedIpF() {
    this.ipSelected = false;
  }

  //set Service account true or false
  selectionChanged() {
    this.useDevice = true;
  }
  selectionChangedf() {
    this.useDevice = false;
  }
  // globalAdmin() {
  //   this.globalAdminSelected = true;
  // }
  // globalAdminF() {
  //   this.globalAdminSelected = false;
  // }

  // Submit data of country and settings
  onSubmit() {
    this.settingForm.patchValue({
      alpha2Code: this.trustedCountries.alpha2Code,
    });
    this.settingForm.patchValue({
      trustedCountries: this.trustedCountries.name,
    });
    this.settingService.addSetting(this.settingForm.value);
    
    this.commonService.OnPush();
  }
  
  ngOnDestroy(): void {
    // submit data on page destroys
    this.onSubmit();

    // End the tour on page destroys
    this.tourEnd()
  }

  // Add selected country to the service function
  onCountrySelected(data) {
    this.trustedCountries = data;
    this.settingService.addCountry(data);
  }
}
