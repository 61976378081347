<nb-header headingSecondary= "{{ 'permission.subtitle' | translate }}"  headingMain="{{ 'permission.head' | translate }}"></nb-header>

<div class="container">
  <div class="row">


     <!-- Text on the left -->
    <div class="col-6  my-auto">
      <div class="pe-2">
        <p>{{'permission.body.text' | translate }}</p>
      </div>
    </div>

    <!-- Consent demo gif on the right -->
    <div class="col-6 side-tour my-auto">
      <div class="text-center">
        <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
          <source  src="../../../assets/media/consent_EN.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    
  </div>
</div>

<!-- Checkbox for the terms and condition -->
<div class="d-flex flex-column">
  <div class=" footer d-flex  pt-3 flex-row-reverse bd-highlight">
    <div class="p-2 bd-highlight"><p class="agreed-link d-flex d-inline"><a class="d-flex d-inline" target="_blank"
      href="../../../../assets/end-user-policy/Privacy_and_Policy_EvolveNow.pdf">  {{ 'permission.body.consentLink' | translate }}</a></p></div>
    <div class="p-2 bd-highlight"><p class="d-flex d-inline">{{ 'permission.body.consent' | translate }}{{ 'permission.body.consent-2' | translate }}</p></div>
    <div class="p-2 bd-highlight"><mat-checkbox (change)="checkCheckBoxvalue($event)" >
    </mat-checkbox></div>
  </div>
</div>



