import { Component, ElementRef, ViewChild } from "@angular/core";
import { OnboardingStore } from "./onboarding/utils/onboarding.store";
import { AnimationBuilder } from "@angular/animations";
import { OnInit } from "@angular/core";
import {
  ActivatedRoute,
  Router,
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { STEP_TYPES } from "app/onboarding/utils/data";

import { Status, Step } from "./onboarding/models/Step";
import { AnimationHelper } from "./onboarding/utils/animationHelper";
import { CommonService } from "./onboarding/services/common.service"; 
import { Location } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { SigninCancelComponent } from "./onboarding/components/signin-error/signin-cancel/signin-cancel.component"; 
import { SigninNotAdminComponent } from "./onboarding/components/signin-error/signin-not-admin/signin-not-admin.component"; 

@Component({
  selector: "nb-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [OnboardingStore],
})
export class AppComponent extends AnimationHelper implements OnInit {
  @ViewChild("el", { static: false }) el: ElementRef; //element for navigation data
  baseRoute: string; //current url params
  consentValue: boolean = false; //user consent check
  islanguageEnglish: boolean = false; //page ranslation value 
  languageValue: string = "ENGLISH"; //page translation text
  lang; //translation value
  tokenId: string;  //token Id value
  onBoardCapable: boolean = false; //user onbaosrding capable value
  public routeStep: number = 1; //naviagation step value
  public FINISHED = Status.FINISHED; //naviagtion status finished value
  public IN_PROGRESS = Status.IN_PROGRESS;
  public type = STEP_TYPES; //navigation steps value
  disableRoute: boolean = false;  //next button disable value
  finishStep: boolean = false; //next button finish value
  finish: boolean = false;
  stepsNav: any = []; //navigation step translation
  substepsNav: any = []; //navigation sub step translation
  translateSubscribe: Subscription; //subscribe to translate change

  private length: number; //navigation length

  public stepList$ = this.store.stepList$; //get navigation steps
  public currentStepIndex$ = this.store.step$; //get the index of navigation
  public selectedStep$ = this.store.finalizedStep$; //get the current steps

  constructor(
    public dialog: MatDialog,
    private readonly store: OnboardingStore,
    public translate: TranslateService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private commonService: CommonService,
    protected builder: AnimationBuilder
  ) {
    super(builder);
    // store language on local storage
    if (localStorage.getItem("language") == "de") {
      this.languageValue = "ENGLISH";
      translate.setDefaultLang("de");
      translate.use("de");
    } else {
      translate.setDefaultLang("en");
      this.languageValue = "DEUTSCH";
      translate.use("en");
      localStorage.setItem("language", "en");
    }
    
  }

  // Find the Token in local storage or in Url param to pass through on boarding
  // Save the token in local storage
  queryToken(){
    this.route.queryParams.subscribe((queryparam) => {
      const querytokendecode = queryparam.token
      this.tokenId = encodeURIComponent(queryparam.token)
      if(querytokendecode){
        this.onBoardCapable = true
        localStorage.setItem("token",this.tokenId);
        this.tokenId = queryparam.token;
        console.log(this.tokenId + "tokenId")
      }else{
        if(localStorage.getItem("token")){
          this.onBoardCapable = true
          this.tokenId = localStorage.getItem('token')
        }
        else{
        this.onBoardCapable = false
      }
      
      }


    } )
  }

  // Translation for Menu navigation
  getTranslateNav() {
    this.translateSubscribe = this.translate
      .stream("menu.steps.permission")
      .subscribe((text: string) => {
        this.stepsNav[0] = text;
      });
    this.translateSubscribe = this.translate
      .stream("menu.steps.tenant")
      .subscribe((text: string) => {
        this.stepsNav[1] = text;
      });
    this.translateSubscribe = this.translate
      .stream("menu.steps.admin")
      .subscribe((text: string) => {
        this.stepsNav[2] = text;
      });
    this.translateSubscribe = this.translate
      .stream("menu.steps.setting")
      .subscribe((text: string) => {
        this.stepsNav[3] = text;
      });
    this.translateSubscribe = this.translate
      .stream("menu.steps.submit")
      .subscribe((text: string) => {
        this.stepsNav[4] = text;
      });
    this.translateSubscribe = this.translate
      .stream("menu.steps.success")
      .subscribe((text: string) => {
        this.stepsNav[5] = text;
      });


    this.translateSubscribe.unsubscribe;
  }

  
  dialogCancel() {
    const dialogRef = this.dialog.open(SigninCancelComponent);
  
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  // Show mat-dialog when user is not signed in with admin
  dialogNoAdmin() {
    const dialogRef = this.dialog.open(SigninNotAdminComponent);
  
    dialogRef.afterClosed().subscribe((result) => {
    });
  }
  ngOnInit(): void {
    // check url param for user canceled signin on microsoft or signed in without an admin account
    this.route.queryParams
      .subscribe(params => {
        const admin_consent = params.admin_consent;
        const error = params.error;
        const error_description = params.error_description;
        const error_subcode = params.error_subcode
        const url = "/onboarding";
            if(admin_consent === 'True' && error == undefined){
              this.consentValue = true;
              this.location.go(url)
              this.successReload();
            }if(error == 'access_denied') {
              if(error_subcode == 'cancel'){
                this.location.go(url)
                this.successReload();
                this.dialogNoAdmin()
              }if(error_description !== undefined){
                this.location.go(url)
                this.successReload();
                this.dialogCancel();
              } 
            }   
      }
    );
    this.queryToken();
  
    this.commonService.getValue().subscribe((value) => {
      this.disableRoute = value;
    });
    this.commonService.getFinishStep().subscribe((value) => {
      this.finishStep = value;
    });
    this.commonService.getFinish().subscribe((value) => {
      this.finish = value;
    });
    
    this.getTranslateNav();
    this.lang = this.translate.getLangs();

    this.store
      .select((s) => s.stepsLength)
      .subscribe((length) => {
        this.length = length;
      });
    this.init();
  }

  private init(): void {
    this.store.setLength();
    this.store.updateStep({ ind: 0 });
  }

  // Language change translation and store language in local storage and set default language
  OnlanguageChange() {
    this.islanguageEnglish = !this.islanguageEnglish;
    if (localStorage.getItem("language") == "en") {
      this.translate.setDefaultLang("de");
      this.languageValue = "ENGLISH";
      this.translate.use("de");
      localStorage.setItem("language", "de");
      window.location.reload();
    } else {
      this.translate.setDefaultLang("de");
      this.languageValue = "DEUTSCH";
      this.translate.use("en");
      localStorage.setItem("language", "en");
      window.location.reload();
    }
  }
  /**
   * Identify which elements have changed in list to properly re-render DOM elements .
   */
  public trackByFn(_, item): number {
    return item.stepIndex;

  }

  /**
   * Calculates substep nav collapse height
   * @param item step item
   * @param itemRef item html element reference
   */
  public collapse(item: Step, itemRef: HTMLLIElement) {
    if (item.collapsed && item.subStep && item.subStep.length > 0) {
      const { height } = itemRef.getBoundingClientRect();
      let len = item.subStep.length;
      if (item.data) len += 1;
      const marginTop = 15;
      const subStepTotalHeight = (25 + marginTop) * len;
      const stepItemHeight = height > 30 ? 30 : height;

      return {
        height: `${stepItemHeight + subStepTotalHeight}px`,
      };
    }
  }

  // next button update steps function
  update(ind: number, el: HTMLElement) {
    if (ind < this.length - 1) {
      this.store.finishCurrentStep();
      this.store.updateStep({ ind: ind + 1 });
    } else {
      // final step, cleanup can be done here
      this.store.finishCurrentStep();
    }
    if (this.routeStep == 7) {
      this.routeStep = 7;
    } else {
      this.routeStep++;
    }
    this.router.navigate([`${this.routeStep}`]);
  }

  // reload function to the elment if consent is true or login is true
  successReload() {
    const nim = 0;
    const dol = this.el.nativeElement;
    this.newupdate(nim, dol);
  }
  
  // next button update steps after consent or login function
  newupdate(nim: number, dol: HTMLElement) {
    this.animate(this.slideOut, dol).onDone(() => {
      if (nim < this.length - 1) {
        this.store.finishCurrentStep();
        this.store.updateStep({ ind: nim + 1 });
        this.animate(this.slideIn, dol);
      } else {
        // final step, cleanup can be done here
        this.store.finishCurrentStep();
      }
    });
  }
  
  // next button update steps for finish step
  updated(ind: number, el: HTMLElement) {
    this.animate(this.slideOut, el).onDone(() => {
      if (ind < this.length - 1) {
        this.store.finishCurrentStep();
        this.store.updateStep({ ind: ind + 1 });
        this.animate(this.slideIn, el);
        if (this.baseRoute && this.finishStep) {
          // this.Onpost();
        } else {
        }
      } else {
        // final step, cleanup can be done here
        this.store.finishCurrentStep();
      }
    });
  }
// previous button update steps function
  preved(ind: number, el: HTMLElement) {
    this.animate(this.slideOut, el).onDone(() => {
      this.store.undoCurrentStep();
      this.store.updateStep({ ind: ind - 1, undo: true });
      this.animate(this.slideIn, el);
    });
  }
}
