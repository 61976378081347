import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from '../onboarding/components/profile/profile.component';
import { MsalGuard } from '@azure/msal-angular';
import { TenantOverviewComponent } from '../onboarding/components/tenant-overview/tenant-overview.component';
import { AppPermissionComponent } from '../onboarding/components/app-permission/app-permission.component';
import { AdminComponent } from '../onboarding/components/admin/admin.component';
import { SettingsComponent } from '../onboarding/components/settings/settings.component';
import { BillingComponent } from '../onboarding/components/billing/billing.component';
import { PriceOverviewComponent } from '../onboarding/components/price-overview/price-overview.component';
import { PaymentComponent } from '../onboarding/components/payment/payment.component';
import { AppComponent } from '../app.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'onboarding',
    pathMatch: 'full',
  },
  {
    path: 'onboarding',
    component: AppComponent,
   }
];


const isIframe = window !== window.parent && !window.opener;
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabled' : 'disabled'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
