<div>
  <div class="row" *ngIf="loading">
    <div class="col-12 ">
      <div class="row height-60">

        <!-- loading animation -->
        <div class=" col-12 admin-img-container d-flex align-items-center justify-content-center">
          <mat-spinner></mat-spinner>
        </div>

      </div>
    </div>
  </div>

  <div *ngIf="!loading">

    <!-- Heading of the page changes text according to the status of the post to Database -->
    <nb-header
      *ngIf="!isIdExist && !errorPage; else failPostHead"
      headingSecondary="{{ 'finish.subtitle' | translate }}"
      headingMain="{{ 'finish.head' | translate }}"
    >
    </nb-header>
 
    <!-- Show text if the Id is exist -->
    <ng-template #failPostHead>
      <div *ngIf="!errorPage; else errorPageHead ">
        <div class="text-center mt-4"><h2 class="text-brand">{{ "finish.idExistTitle" | translate }}</h2></div>
      </div>
    </ng-template>

    <!-- Show text if the Http Error -->
    <ng-template #errorPageHead>
      <div>
        <div class="text-center mt-4"><h2 class="text-brand">Something went wrong</h2></div>
      </div>
    </ng-template>

    <!-- Show text if post is successfull else show post is unsuccesful(Id exist)-->
    <div *ngIf="!isIdExist && !errorPage; else failPostBody" color="container height-80">
      <div>
        <div class="row">
          <div class="col-12">
            <div class="text-center p-4 mt-4">

              <!-- Success image -->
              <div>
                <img src="../assets/img/page-img/success.png" alt="" style="max-width: 200px;">
                  <div class="mt-4">
                    <a
                      href="https://evolve365securityportaldev.azurewebsites.net/"
                      class="btn btn-success me-3"
                      >{{ "finish.btn" | translate }}
                    </a>
                  </div>
              </div>

              <!-- contact to mail text -->
              <div class="mt-4">
                <h6 class="pe-4 ps-4"> {{"finish.question" | translate }}</h6> 
                <a href="mailto:&#105;&#110;&#102;&#111;&#64;&#101;&#118;&#111;&#108;&#118;&#101;&#51;&#54;&#53;&#46;&#99;&#108;&#111;&#117;&#100;&#10;">&#105;&#110;&#102;&#111;&#64;&#101;&#118;&#111;&#108;&#118;&#101;&#51;&#54;&#53;&#46;&#99;&#108;&#111;&#117;&#100;&#10;</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Text body for post successfull post failed -->
    <ng-template  #failPostBody color="container height-80">
      <div *ngIf="!errorPage; else errorPageBody">
        <div class="row">
          <div class="col-12">
            <div class="text-center p-4">
              <div>
                <!-- Post failed image -->
                <div class="text-center">
                  <img src="../assets/img/page-img/confused.png" alt="" style="max-width: 200px;">
                  <!-- Post fail subtext -->
                  <h6>
                    {{ "finish.idExistMessage" | translate }}
                  </h6>
                  <h6 class="mt-4">{{ "finish.ifThink" | translate }}</h6>
                    <h6>{{"finish.contact" | translate }}</h6>
                    <!-- contact emil text -->
                  <div >
                    <a
                      href="mailto:&#105;&#110;&#102;&#111;&#64;&#101;&#118;&#111;&#108;&#118;&#101;&#51;&#54;&#53;&#46;&#99;&#108;&#111;&#117;&#100;&#10;"
                      >&#105;&#110;&#102;&#111;&#64;&#101;&#118;&#111;&#108;&#118;&#101;&#51;&#54;&#53;&#46;&#99;&#108;&#111;&#117;&#100;&#10;</a
                    >
                  </div>

                  <!-- Portal app rdirect button -->
                  <div class="mt-4">
                    <a
                      href="https://evolve365securityportaldev.azurewebsites.net/"
                      class="btn btn-success"
                      >{{ "finish.btn" | translate }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

     <!-- Text body for post successfull post failed -->
    <ng-template #errorPageBody>
      <div>
        <div class="row">
          <div class="col-12">
            <div class="text-center p-4 mt-4">

              <!-- Success image -->
              <div>
                <h1 class="errorText">HTTP {{httpError}} ERROR</h1>
              </div>

              <!-- contact to mail text -->
              <div class="mt-4">
                <h6 class="pe-4 ps-4"> {{"finish.question" | translate }}</h6> 
                <a href="mailto:&#105;&#110;&#102;&#111;&#64;&#101;&#118;&#111;&#108;&#118;&#101;&#51;&#54;&#53;&#46;&#99;&#108;&#111;&#117;&#100;&#10;">&#105;&#110;&#102;&#111;&#64;&#101;&#118;&#111;&#108;&#118;&#101;&#51;&#54;&#53;&#46;&#99;&#108;&#111;&#117;&#100;&#10;</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
