import { Injectable } from '@angular/core';
import { Company } from '../model-form/company.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private company: Company = {
    licensedUsers: 0,
  };
  getCompany() {
    return this.company;
  }
  addCompany(company: Company) {
    this.company = company;
  }
  constructor() { }
}
