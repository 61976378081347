import {
  Component,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { CommonService } from "app/onboarding/services/common.service";

@Component({
  selector: "nb-app-permission",
  templateUrl: "./app-permission.component.html",
  styleUrls: ["./app-permission.component.scss"],
})
export class AppPermissionComponent implements OnInit, OnDestroy {
  // variables
  checked: boolean = false; // Agree to the terms check box

  constructor(
    public dialog: MatDialog,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    
    // Set check box value to uncheck on page loads
    this.checkCheckBoxvalue;
  }

  // check-box set the value checked or 
  checkCheckBoxvalue(event) {
    this.checked = event.checked;
    if (this.checked) {
      this.commonService.setValue(true);
    } else {
      this.commonService.setValue(false);
    }
  }

  ngOnDestroy(): void {
    // set next button disabled after page destoys
    this.commonService.setValue(false);
  }
}
