import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TenantIdService {
  private tenantID: string = ''

  getTenantID() {
    return this.tenantID;
  }
  constructor() {}
  addTenantId(id: string) {
    this.tenantID = id;
  }
}
