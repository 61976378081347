import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerData } from 'app/onboarding/models/comstomerData.model';
import { Setting } from 'app/onboarding/model-form/setting.model';
import { SettingService } from 'app/onboarding/services/setting.service';
import { CommonService } from 'app/onboarding/services/common.service'; 
import { StatusService } from 'app/onboarding/services/status.service';

@Component({
  selector: 'nb-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class SubmitComponent implements OnInit, OnDestroy{
submitData:CustomerData; //users data model
data:Setting; //setting data
ipGlobal: any; //static Ip address 
isIp: boolean; //static Ip option
device:any; // Service account email
date: any; // current date
newDate:any =[ '','',''] //current date format
 

isDevice:boolean;
  constructor(private commonService: CommonService, private settingService: SettingService, private statusService: StatusService) { 
    this.commonService.OnPush();
   
  }

  ngOnInit(): void {
    // assign data to variables from services
    this.submitData = this.commonService.OngetData();
    console.log(this.submitData)
    this.data =  this.settingService.getSetting();
    this.ipGlobal = this.data.staticIPAddressUsed;
    this.device = this.data.serviceAccountUsed;
    this.date = this.data.assignmentDate


    this.getIp();
    this.getDate(this.date)

    // set the next button text to submit
    this.commonService.setFinishStep(true);
   
  }

  // change the satatic ip type to boolean
  getIp(){
    this.ipGlobal === 'true' ?  this.isIp = true : this.isIp = false;
    this.device === 'true' ? this.isDevice = true : this.isDevice = false;
    
  }

  // set the date format to europe
  getDate(date){
    this.newDate[0] =  date.getDate();
    this.newDate[1] =  date.getMonth()+1;
    this.newDate[2]=  date.getFullYear();
  }
  
ngOnDestroy(): void {

  // remove the token from local storage on page destroy
  localStorage.removeItem('token')
  // set the next button to default
  this.commonService.setFinishStep(false);

  // set the next button to default
  this.commonService.setFinish(false);
  
}

  
}
