import { Component, OnDestroy, OnInit, Inject, Input } from "@angular/core";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { msalConfig } from "app/auth-config"; 

import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from "@azure/msal-angular";

import {
  AuthenticationResult,
  InteractionStatus,
  PopupRequest,
} from "@azure/msal-browser";

import { ConfigService } from "app/onboarding/services/config.service";
const appId = msalConfig.auth.clientId;
const redirectUrl = msalConfig.auth.redirectUri;
// const appId = "84280e82-6178-47a7-886d-98d3002f8218";
// const redirectUrl = "http://localhost:4200/";
@Component({
  selector: "nb-tenant-overview",
  templateUrl: "./tenant-overview.component.html",
  styleUrls: ["./tenant-overview.component.scss"],
})
export class TenantOverviewComponent implements OnInit, OnDestroy {
  //Variable//
  isIframe = false; //Msal iframe
  redirectUri: string; //Msal Redirect Url
  appID: string; //Msal App Id
  loginDisplay = false; //Logedin check
  isSecureScore: boolean = false; // Mfa and Secure score toggle switch
  consentUrl: string; //Consent Url
  @Input() consentValue: boolean; //check if the user gave consent or not
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private config: ConfigService
  ) {
    // this.redirectUri = this.config.getSettings('msal').auth.redirectUri //get redirect uri from app_initializer
    // this.appID = this.config.getSettings('msal').auth.clientId //get App Id from app_initializer

    // this.consentUrl = `https://login.microsoftonline.com/common/adminconsent?client_id=${this.appID}&redirect_uri=${this.redirectUri}`; //admin consent url
    this.consentUrl = `https://login.microsoftonline.com/common/adminconsent?client_id=${appId}&redirect_uri=${redirectUrl}&sso_reload=true`; //admin consent url
  }

  ngOnInit(): void {
    //Iframe for msal login
    this.isIframe = window !== window.parent && !window.opener;

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  //Get login account and check for login status
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  // user login function
  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  // user logout function
  logout() {
    this.authService.logoutPopup();
  }

  // Msal and secure score toggle event function
  secureScore(e) {
    this.isSecureScore = e;
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
